<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="90%"
        align-center
        class="process-editor"
        @keyup.enter="doConfirm">
        <el-form
            ref="dataform"
            class="process-form"
            :model="data"
            :rules="rules"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="工序类型" prop="cate_code">
                        <el-select
                            id="cate_code"
                            v-model="data.cate_code"
                            autocomplete="off"
                            placeholder="请选择"
                            @change="handleSelectChange">
                            <el-option
                                v-for="(category_data, i) in category_list"
                                :key="i"
                                :label="category_data.name"
                                :value="category_data.code" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="上道工序" prop="last_process">
                        <el-checkbox-group
                            id="last_process"
                            v-model="data.last_process"
                            autocomplete="off" p
                            laceholder="请选择">
                            <el-checkbox
                                v-for="(pdata, i) in options.process_list"
                                :key="i"
                                :value="pdata.name"
                                :label="pdata.name" />
                        </el-checkbox-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="工序名称" prop="name">
                        <el-input
                            id="name"
                            v-model="data.name"
                            autocomplete="off"
                            placeholder="请输入"
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="规格" prop="standard">
                        <el-input
                            id="standard"
                            v-model="data.standard"
                            autocomplete="off"
                            placeholder="请输入"
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="结构" prop="structure">
                        <el-input
                            id="structure"
                            v-model="data.structure"
                            autocomplete="off"
                            placeholder="请输入"
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="产出单位" prop="unit">
                        <el-select
                            id="unit"
                            v-model="data.unit"
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option label="米" value="米" />
                            <el-option label="个" value="个" />
                            <el-option label="条" value="条" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="产量比例" prop="output_ratio">
                        <el-input
                            id="output_ratio"
                            v-model="data.output_ratio"
                            autocomplete="off"
                            placeholder="产出和投入比值,例如投入1米,产出4个袋子,即比例为4"
                            clearable
                            maxlength="10" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="text-align: left;">
                    <span>
                        产量比例示例:以制袋最终产出10000为例，印刷产出1000,即该数值为1000,分切2000,即为2000,制袋为10000
                    </span>
                </el-col>
            </el-row>
        </el-form>
        <el-form v-if="param_show" :model="params" class="process-form">
            <el-row
                v-for="count in paramRowsCal(param_list, param_row_size)"
                :key="count">
                <el-col
                    v-for="(param_data,i) in paramListCal(count, param_row_size, param_list)"
                    :key="i"
                    :span="12">
                    <el-form-item :label="param_data.name" :prop="param_data.name">
                        <el-input
                            v-if="param_data.args.style=='input'"
                            :id="param_data.name"
                            v-model="params[param_data.name]"
                            autocomplete="off"
                            :placeholder="param_data.args.detail"
                            clearable
                            @blur="inputChecked(param_data)" />
                        <el-select
                            v-if="param_data.args.style=='select'"
                            :id="param_data.name"
                            v-model="params[param_data.name]"
                            autocomplete="off"
                            :placeholder="param_data.args.detail">
                            <el-option
                                v-for="(v, k) in param_data.args.opts"
                                :key="k"
                                :label="v"
                                :value="v" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="visibled = false">取消</el-button>
                <el-button type="primary" :disabled="!validate" @click="doConfirm">
                    提交
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import {notify} from '../assets/js/utils.js';
export default {
    name: 'DialogProcess',
    components: {
    },
    props: {
    },
    emits: ['confirm'],
    data: function() {
        return {
            title: '工序添加',
            options: {},
            visibled: false,
            param_show: false,
            resolve: null,
            reject: null,
            data: {
                cate_code: '',
                name: '',
                last_process: '',
                structure: '',
                standard: '',
                unit: '',
                output_ratio: '',
            },
            rules: {
                cate_code: [{required: true, message: '请选择工序类型', trigger: ['change', 'blur']}],
                name: [{required: true, message: '请输入工序名', trigger: ['change', 'blur']}],
                unit: [{required: true, message: '请选择产出单位', trigger: ['change', 'blur']}],
                output_ratio: [{required: true, message: '请输入产出比例', trigger: ['change', 'blur']}],
            },
            params: {},
            param_list: [],
            param_row_size: 2,
            category_list: [],
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    watch: {
        param_list: {
            handler: function(newval) {
                if (newval.length > 0 && this.params) {
                    this.param_show = true;
                }
            },
            deep: true,
        },
        params: {
            handler: function(newval) {
                if (this.param_list.length > 0 && newval) {
                    this.param_show = true;
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.initCategory();
    },
    methods: {
        async show(options) {
            this.title = options.title;
            this.options = options;
            this.visibled = true;
            this.data = options.process_data;
            await this.handleSelectChange();
            this.params = options.process_data.param_hide;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm(event) {
            try {
                if (!(await this.$refs.dataform?.validate())) {
                    return;
                }
                if (!this.checked()) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            this.data.params = this.paramsFormat();
            const category_name = this.category_list.find(item => item.code === this.data.cate_code).name;
            const data = {
                event,
                options: this.options,
                param_hide: this.params,
                param_list: this.param_list,
                cate_name: category_name,
                ...this.data,
            };
            // resolve
            this.resolve(data);
            // event confirm
            this.$emit('confirm', data);
            // disable reject
            this.reject = null;
            // close dialog
            this.visibled = false;
            this.param_show = false;
        },
        async handleSelectChange() {
            const url = `/api/parameter-defineds/?t=1&c=${this.data.cate_code}`;
            const res = await this.axios.get(url);
            this.param_list = res.data.data;
            this.params = {};
            for (let i = 0; i < this.param_list.length; i++) {
                this.params[this.param_list[i].name] = '';
            }
        },
        paramListCal(count, size, data_list) {
            const list_len = data_list.length;
            const min_index = count * size;
            const max_index = (count + 1) * size > list_len && list_len || (count + 1) * size;
            return data_list.slice(min_index, max_index);
        },
        paramRowsCal(data_list, size) {
            if (data_list) {
                const max = Math.ceil(data_list.length / size);
                return new Array(max).keys();
            } else {
                return [];
            }
        },
        // 组合params字符串
        paramsFormat() {
            const params = [];
            for (let i = 0; i < this.param_list.length; i++) {
                if (this.params[this.param_list[i].name]) {
                    params.push(`${this.param_list[i].name}:${this.params[this.param_list[i].name]}`);
                }
            }
            return params.join(';');
        },
        async initCategory() {
            const url = `/api/categories/`;
            const res = await this.axios.get(url);
            this.category_list = res.data.data;
        },
        checked() {
            let flag = true;
            if (this.data.name) {
                for (let i = 0; i < this.options.process_list.length; i++) {
                    if (this.data.name.trim()
                    === this.options.process_list[i]['name'].trim()) {
                        notify('error', '工序名称重复！', 0);
                        flag = false;
                        break;
                    }
                }
                if (!flag) {
                    return flag;
                }
            }
            if (this.data.output_ratio) {
                if (!(!isNaN(this.data.output_ratio) && this.data.output_ratio > 0)) {
                    notify('error', `产出比例必须为大于0的数字！`, 0);
                    flag = false;
                }
                if (!flag) {
                    return flag;
                }
            }
            if (this.param_list.length > 0) {
                for (let i = 0; i < this.param_list.length; i++) {
                    if (!this.params[this.param_list[i]['name']]
                    || this.params[this.param_list[i]['name']].length === 0) {
                        notify('error', `${this.param_list[i]['name']}的值为空`, 0);
                        flag = false;
                        break;
                    }
                    if (this.param_list[i]['type'] === 'integer') {
                        console.log(Number.isInteger(this.params[this.param_list[i]['name']]),
                            this.params[this.param_list[i]['name']]);
                        if (!Number.isInteger(parseInt(this.params[this.param_list[i]['name']]))) {
                            notify('error', `${this.param_list[i]['name']}的值必须为大于0的整数！`, 0);
                            flag = false;
                            break;
                        }
                    }
                    if (this.param_list[i]['type'] === 'list[integer]'
                    || this.param_list[i]['type'] === 'range[integer]') {
                        for (let i = 0; i < this.params[this.param_list[i]['name']].length; i++) {
                            if (!Number.isInteger(
                                parseInt(this.params[this.param_list[i]['name']][i]),
                            )) {
                                notify('error', `${this.param_list[i]['name']}的值必须为大于0的整数！`, 0);
                                flag = false;
                                break;
                            }
                        }
                        if (!flag) {
                            break;
                        }
                    }
                };
                if (!flag) {
                    return flag;
                }
            }
            return flag;
        },
        inputChecked(param_data) {
            if (!this.params[param_data['name']] || this.params[param_data['name']].length === 0) {
                notify('error', `${param_data['name']}的值为空`, 0);
                return;
            }
            if (param_data['type'] === 'integer') {
                if (!Number.isInteger(parseInt(this.params[param_data['name']]))) {
                    notify('error', `${param_data['name']}的值必须为大于0的整数！`, 0);
                    this.parms[param_data['name']] = '';
                    return;
                }
            }
            if (param_data['type'] === 'list[integer]' || param_data['type'] === 'range[integer]') {
                const param_str = this.params[param_data['name']].replaceAll('，', ',');
                for (let i = 0; i < param_str.split(',').length; i++) {
                    if (!Number.isInteger(parseInt(param_str[i]))) {
                        notify('error', `${param_data['name']}的值必须为大于0的整数！`, 0);
                        break;
                    }
                }
                this.parms[param_data['name']] = '';
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.process-editor{
    max-width: 1000px;
    min-width: 600px;
}
.process-form .el-form-item__label{
    width: 90px;
    text-align: justify;
    text-align-last: justify;
    display: block;
}
.process-form span{
    font-style: italic;
    text-align: left;
}
</style>
