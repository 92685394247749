<template>
    <el-table
        ref="elTable"
        class="process_table"
        :data="data"
        height="100%"
        :highlight-current-row="true"
        :border="true"
        :row-key="row => row.name">
        <el-table-column fixed type="expand">
            <template #default="props">
                <div>
                    <div class="info-box">
                        <div
                            v-for="(item, i) in cardItems"
                            :key="i"
                            class="info-item"
                            :style="{width: item.width}">
                            {{ item.label }}:
                            {{
                                item.formatter
                                    ? item.formatter(props.row[item.prop])
                                    : props.row[item.prop]
                            }}
                        </div>
                    </div>
                    <tabProcessTags :data="props.row.params.split(';')" />
                </div>
            </template>
        </el-table-column>
        <el-table-column fixed type="index" align="center" />
        <el-table-column prop="cate_name" label="工序类型" width="150" align="center" />
        <el-table-column prop="name" label="工序名称" width="150" align="center" />
        <el-table-column prop="last_process" label="前道工序" width="150" align="center" />
        <el-table-column prop="structure" label="结构" width="180" align="center" />
        <el-table-column prop="standard" label="规格" width="180" align="center" />
        <el-table-column prop="params" label="参数" align="center" />
    </el-table>
</template>

<script>
import tabProcessTags from './tab-process-tags.vue';
export default {
    components: {
        tabProcessTags,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        cardItems() {
            return [
                {prop: 'cate_name', label: '分类', width: 80},
                {prop: 'name', label: '工序名称', width: 120},
                {prop: 'last_process', label: '前道工序', width: 120},
                {prop: 'structure', label: '结构', width: 80},
                {prop: 'standard', label: '规格', width: 80},
            ];
        },
    },
    methods: {
    },
};
</script>
<style scoped>
.info-box {
    display: flex;
    flex-wrap: wrap;
}
.info-item {
    min-width: 300px;
    width: 25%;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
