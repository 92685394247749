/**
 * intervals: [[start, end], ...]
 **/

/**
 * 计算两个interval序列差值
 * @intervas: 被减数
 * @others: 减数
 * return: new intervals
 **/
function intervals_sub(intervals, others) {
    const rs = [];
    let i = 0;
    intervals.forEach(([xs, xe]) => {
        for (; i < others.length; i++) {
            const [os, oe] = others[i];
            if (oe <= xs) continue;
            if (oe < xe) {
                if (xs < os) {
                    rs.push([xs, os]);
                }
                xs = oe;
            } else {
                if (xe <= os) {
                    rs.push([xs, xe]);
                } else if (xs < os) {
                    rs.push([xs, os]);
                }
                return;
            }
        }
        rs.push([xs, xe]);
    });
    return rs;
}

/**
 * 对于一个interval序列，给定起始值，返回指定合计间隔尺寸的序列
 * @intervals: 原始序列
 * @start: 起始值
 * @size: 计划返回合计尺寸
 * return success, new intervals; no enuph size return null;
 **/
function intervals_get(intervals, start, size) {
    const rs = [];
    let i = 0;
    for (; i < intervals.length; i++) {
        if (start < intervals[i][1]) break;
    }
    for (; i < intervals.length; i++) {
        const [xs, xe] = intervals[i];
        const ms = Math.max(xs, start);
        if (xe - ms >= size) {
            rs.push([ms, ms + size]);
            return rs;
        }
        rs.push([ms, xe]);
        size -= xe - ms;
    }
    return null;
}

// function intersection(...args) {
//   return args.reduce((a, b) => {
//     if (!a) return null;
//     const lo = a[0] > b[0] ? a[0] : b[0];
//     const hi = a[1] < b[1] ? a[1] : b[1];
//     return lo < hi ? [lo, hi] : null;
//   });
// }

export default {
    install(app) {
        app.config.globalProperties.$intervals = {
            sub: intervals_sub,
            get: intervals_get,
        };
    },
};

export {intervals_get, intervals_sub};
