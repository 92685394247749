<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        title="选择机台"
        align-center
        draggable
        width="75%"
        append-to-body
        modal-class="mac-list-dlg"
        :before-close="handleClose"
        @keyup.enter="doConfirm">
        <el-form
            :model="args">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="机台" prop="machine">
                        <el-input
                            v-model="args.machine"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="6">
                    <el-form-item label="类型" class="label_style" prop="category">
                        <el-select
                            ref="categorySelect"
                            v-model="args.category"
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option
                                v-for="(category_data, i) in category_list"
                                :key="i"
                                :label="category_data.name"
                                :value="category_data.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="9" />
                <el-col :span="2">
                    <el-button
                        type="primary"
                        @click="init_data">
                        <el-icon><Search /></el-icon>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table
            class="machine_list"
            :data="machine_list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id"
            @row-click="selectPrd"
            @row-dblclick="returnPrd">
            <el-table-column fixed type="index" align="center" />
            <el-table-column
                v-for="(col, i) in tableCols"
                :key="i"
                :label="col.label"
                v-bind="col"
                header-align="center"
                :align="col.align" />
        </el-table>
        <el-pagination
            align="center"
            class="page"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="machine_list.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="handleClose">关闭</el-button>
                <el-button type="primary" @click="doConfirm">
                    确认
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import {Search} from '@element-plus/icons-vue';
export default {
    name: 'MachineListDlg',
    components: {
        Search,
    },
    data: function() {
        return {
            visibled: false,
            resolve: null,
            reject: null,
            args: {
                machine: '',
                category: '',
            },
            machine_list: [],
            category_list: [],
            param_defined_list: [],
            seleced_prd: null,
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 10, // 每页的数据条数
        };
    },
    computed: {
        tableCols() {
            return [
                {prop: 'code', label: '编码', width: 150, align: 'center'},
                {
                    prop: 'name',
                    label: '名称',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'cate',
                    label: '类型',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'speed',
                    label: '最大机速',
                    width: 120,
                    align: 'center',
                },
                {prop: 'param_str', label: '参数', align: 'left', width: 200},
                {prop: 'state_name', label: '状态', align: 'center', width: 120},
            ];
        },
    },
    methods: {
        async show(option) {
            // await this.initCategory();
            this.visibled = true;
            await this.init_data(option);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async init_data(option) {
            await this.initParams();
            const url = `/api/machines/`;
            const args = [];
            if (this.args.machine) {
                args.push(`q=${this.args.machine}`);
            }
            // if (this.args.category) {
            //     args.push(`t=${this.args.category}`);
            // }
            if (option.category) {
                args.push(`t=${option.category}`);
            }
            const args_url = args.length > 0 && '?' + args.join('&') || '';
            const res = await this.axios.get(url + args_url);
            this.machine_list = res.data.data;
            for (let i = 0; i < this.machine_list.length; i++) {
                this.machine_list[i]['cate'] = this.machine_list[i]['category']['name'];
                this.machine_list[i]['state_name'] = this.machine_list[i]['state'] > 0 && '活跃' || '冻结';
                this.machine_list[i]['param_list'] = this.formatParams(this.machine_list[i]['data']);
                this.machine_list[i]['param_str'] = this.machine_list[i]['param_list'].join(';');
            }
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        async doConfirm() {
            this.resolve(this.seleced_prd);
            this.reject = null;
            this.visibled = false;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        async initCategory() {
            const url = `/api/categories/`;
            const res = await this.axios.get(url);
            this.category_list = res.data.data;
        },
        async initParams() {
            const url = `/api/parameter-defineds/?t=2`;
            const res = await this.axios.get(url);
            this.param_defined_list = res.data.data.reduce((res, data) => {
                res[data['name']] = data;
                return res;
            }, {});
        },
        formatParams(json) {
            const param_list = [];
            const param_defined_list = this.param_defined_list;
            Object.keys(json).forEach(function(key) {
                if (param_defined_list[key]?.type === 'range') {
                    param_list.push(`${key}:${json[key].join('~')}`);
                } else {
                    param_list.push(`${key}:${json[key]}`);
                }
            });
            return param_list;
        },
        selectPrd(row) {
            this.seleced_prd = row;
        },
        handleClose() {
            this.visibled = false;
            this.seleced_prd = null;
            this.reject = null;
            this.resolve(null);
        },
        returnPrd(row) {
            this.seleced_prd = row;
            this.resolve(this.seleced_prd);
            this.reject = null;
            this.visibled = false;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mac-list-dlg {
    border: 1px solid red;
    width:100%;
}
.machine_list{
    max-height: v-bind('max_height');
    min-height: v-bind('max_height');
    margin-top: 5px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
</style>
