<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="30%"
        align-center
        class="range-dialog"
        @keyup.enter="doConfirm">
        <el-table
            ref="elTable"
            :data="data"
            :border="true">
            <el-table-column type="index" align="center" label="" width="30" />
            <el-table-column label="起始" prop="start" header-align="center" align="center">
                <template #default="props">
                    <el-input
                        v-model="props.row['start']"
                        autocomplete="off"
                        clearable />
                </template>
            </el-table-column>
            <el-table-column label="截至" prop="end" header-align="center" align="center">
                <template #default="props">
                    <el-input
                        v-model="props.row['end']"
                        autocomplete="off"
                        clearable />
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="" width="80" align="center">
                <template #header>
                    <el-button
                        class="btn-add"
                        @click="add_col()">
                        <el-icon><plus /></el-icon>
                    </el-button>
                </template>
                <template #default="props">
                    <el-tooltip content="删除" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            @click="del_col($event, props.$index)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <footer>
            <el-button @click="visibled = false">取 消</el-button>
            <el-button type="primary" @click="doConfirm">确 定</el-button>
        </footer>
    </el-dialog>
</template>

<script>
import {notify} from '../assets/js/utils.js';
import {Plus, Delete} from '@element-plus/icons-vue';
export default {
    name: 'InputRangeList',
    components: {
        // inputNumberRange,
        Plus,
        Delete,
    },
    props: {
    },
    data() {
        return {
            data: [],
            res: [],
            visibled: false,
            resolve: null,
            reject: null,
            title: '',
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    mounted() {
    },
    methods: {
        async show(options) {
            this.visibled = true;
            this.title = options.title;
            this.data = options.data.reduce((res, data) => {
                res.push({
                    'start': data[0],
                    'end': data[1],
                });
                return res;
            }, []);
            // this.data =
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        doConfirm() {
            if (!this.checked()) {
                return;
            }
            this.resolve({
                'name': this.title,
                'value': this.res,
            });
            this.reject = null;
            this.visibled = false;
        },
        checked() {
            let flag = true;
            this.res = this.data.reduce((res, d) => {
                if (d['start'] && d['end']) {
                    res.push([d['start'], d['end']]);
                }
                return res;
            }, []);
            if (this.res.length === 0) {
                flag = false;
                notify('error', `${this.title}的值不能是空！`, 0);
                return flag;
            }
            for (let i = 0; i < this.res.length; i++) {
                if (!(Number.isInteger(parseInt(this.res[i][0]))
                && Number.isInteger(parseInt(this.res[i][1])))) {
                    flag = false;
                    notify('error', `${this.title}第${i + 1}行的值不是整数！`, 0);
                    break;
                }
                if (parseInt(this.res[i][0]) > parseInt(this.res[i][1])) {
                    flag = false;
                    notify('error', `${this.title}第${i + 1}行的起始值不能大于截至值！`, 0);
                    break;
                }
                this.res[i][0] = parseInt(this.res[i][0]);
                this.res[i][1] = parseInt(this.res[i][1]);
            }
            return flag;
        },
        add_col() {
            this.data.push({});
        },
        del_col(_, index) {
            this.data.splice(index, 1);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.range-dialog{
    max-width: 800px;
    min-width: 250px;
}
</style>
