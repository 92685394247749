<template>
    <el-form
        :model="params"
        class="param_form data_form">
        <fieldset
            v-for="(cdata, j) in category_list"
            :key="j"
            class="param_field">
            <legend>
                {{ cdata.name }}
            </legend>
            <el-row>
                <el-col
                    v-for="(data, i) in cdata.param_list"
                    :key="i"
                    :span="12">
                    <el-form-item
                        :label="data.defined.label">
                        <el-input
                            v-if="data.defined.args.style=='input'"
                            :ref="data.defined.label"
                            v-model="params[data.defined.name]"
                            autocomplete="off"
                            :placeholder="data.defined.args.detail"
                            clearable
                            @blur="inputChecked(data.defined)" />
                        <el-select
                            v-if="data.defined.args.style=='select' &&
                                !data.defined.type.includes('list')"
                            v-model="params[data.defined.name]"
                            autocomplete="off"
                            :placeholder="data.defined.args.detail">
                            <el-option
                                v-for="(v, k) in data.defined.args.opts"
                                :key="k"
                                :label="v"
                                :value="v" />
                        </el-select>
                        <el-select
                            v-if="data.defined.args.style=='select' &&
                                data.defined.type.includes('list')"
                            v-model="params[data.defined.name]"
                            multiple
                            autocomplete="off"
                            :placeholder="data.defined.args.detail">
                            <el-option
                                v-for="(v, k) in data.defined.args.opts"
                                :key="k"
                                :label="v"
                                :value="v" />
                        </el-select>
                        <input-number-range
                            v-if="data.defined.args.style=='range'"
                            :param-key="data.defined.name"
                            :value="params[data.defined.name]"
                            :precision="0"
                            @input="input" />
                        <el-input
                            v-if="data.defined.args.style=='switch'"
                            :id="data.defined.name"
                            v-model="params[data.defined.name]"
                            autocomplete="off"
                            :placeholder="data.defined.args.detail"
                            clearable
                            readonly
                            @click="inputSwitchDialog(data.defined)" />
                    </el-form-item>
                </el-col>
            </el-row>
        </fieldset>
    </el-form>
    <div v-if="foot_flag" class="foot_div">
        <el-row>
            <el-col :span="18" />
            <el-col :span="6">
                <el-button
                    class="btn_button"
                    type="primary"
                    @click="doConfirm">
                    保存
                </el-button>
            </el-col>
        </el-row>
    </div>
    <inputSwitchList ref="inputSwitchList" />
</template>
<script>
import inputNumberRange from './inputNumberRange.vue';
import inputSwitchList from './inputSwitchList.vue';
import {notify} from '../assets/js/utils.js';
export default {
    name: 'ParamManage',
    components: {
        inputNumberRange,
        inputSwitchList,
    },
    data: function() {
        return {
            param_list: [],
            param_row_size: 2,
            original_params: null,
            confirm_params: {},
            category_list: [],
            params: {},
            foot_flag: false,
            max_height: document.body.clientWidth * 0.9,
            min_height: document.body.clientWidth * 0.5,
        };
    },
    watch: {
        params: {
            handler: function(newval) {
                let flag = false;
                if (this.original_params) {
                    for (let i = 0; i < this.param_list.length; i++) {
                        const param = this.param_list[i]['defined'];
                        if (param['type'] === 'integer') {
                            if (parseInt(newval[param['name']]) !== this.original_params[param['name']]) {
                                flag = true;
                            }
                            this.confirm_params[param['id']] = parseInt(newval[param['name']]);
                        }
                        if (param['type'].includes('list') || param['type'].includes('range')) {
                            if (JSON.stringify(newval[param['name']])
                            !== JSON.stringify(this.original_params[param['name']])) {
                                flag = true;
                            }
                            if (Array.isArray([newval[param['name']]])
                            && typeof newval[param['name']] !== 'string') {
                                this.confirm_params[param['id']] = newval[param['name']];
                            } else {
                                const str = newval[param['name']].replaceAll('，', ',');
                                this.confirm_params[param['id']] = str.split(',');
                            }
                        }
                        if (param['type'] === 'string') {
                            if (newval[param['name']].toString()
                            !== this.original_params[param['name']].toString()) {
                                flag = true;
                            }
                            this.confirm_params[param['id']] = newval[param['name']]?.toString();
                        }
                        if (param['type'] === 'dict[integer]') {
                            if (newval[param['name']].toString()
                            !== this.original_params[param['name']].toString()) {
                                flag = true;
                            }
                            this.confirm_params[param['id']] = this.stringToJson(newval[param['name']]);
                        }
                    }
                }
                this.foot_flag = flag;
            },
            deep: true,
        },
    },
    mounted() {
        this.init_data();
    },
    methods: {
        async init_data() {
            this.foot_flag = false;
            const url = `/api/parameters/`;
            const res = await this.axios.get(url);
            this.param_list = res.data.data;
            this.params = {};
            this.original_params = null;
            for (let i = 0; i < this.param_list.length; i++) {
                const name = this.param_list[i].defined['name'];
                this.params[name] = this.param_list[i].data
                || this.param_list[i].defined['args']['default'] || '';
                if (this.param_list[i].defined.type === 'dict[integer]') {
                    this.params[name] = this.jsonToString(this.params[name]);
                }
                if (this.param_list[i].defined['args']['unit']) {
                    this.param_list[i].defined['label'] = `${this.param_list[i].defined['name']}
                    (${this.param_list[i].defined['args']['unit']})`;
                } else {
                    this.param_list[i].defined['label'] = this.param_list[i].defined['name'];
                }
            }
            await this.formatParams();
            this.original_params = JSON.parse(JSON.stringify(this.params));
        },
        async formatParams() {
            const url = `/api/categories/`;
            const res = await this.axios.get(url);
            this.category_list = res.data.data;
            for (let i = 0; i < this.category_list.length; i++) {
                const c = this.category_list[i];
                const param_list = [];
                for (let j = 0; j < this.param_list.length; j++) {
                    const p = this.param_list[j];
                    if (c.id === p.defined.cate_id) {
                        param_list.push(p);
                    }
                }
                c['param_list'] = param_list;
            }
            this.category_list = this.category_list.filter(function(item) {
                return item.param_list.length > 0;
            });
        },
        checked() {
            let flag = true;
            console.log(this.confirm_params);
            for (let i = 0; i < this.param_list.length; i++) {
                if (!this.confirm_params[this.param_list[i]['defined']['id']]
                || this.confirm_params[this.param_list[i]['defined']['id']].length === 0) {
                    notify('error', `${this.param_list[i]['defined']['name']}的值为空`, 0);
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        inputChecked(param_data) {
            if (!this.params[param_data['name']] || this.params[param_data['name']].length === 0) {
                notify('error', `${param_data['name']}的值为空`, 0);
                return;
            }
            if (param_data['type'] === 'integer') {
                if (!Number.isInteger(parseInt(this.params[param_data['name']]))) {
                    notify('error', `${param_data['name']}的值必须为大于0的整数！`, 0);
                    this.parms[param_data['name']] = '';
                    return;
                }
            }
            if (param_data['type'] === 'list[integer]' || param_data['type'] === 'range[integer]') {
                const param_str = this.params[param_data['name']].replaceAll('，', ',');
                for (let i = 0; i < param_str.split(',').length; i++) {
                    if (!Number.isInteger(parseInt(param_str[i]))) {
                        notify('error', `${param_data['name']}的值必须为大于0的整数！`, 0);
                        break;
                    }
                }
                this.parms[param_data['name']] = '';
            }
        },
        async doConfirm() {
            try {
                if (!this.checked()) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            const res = await this.axios.put(`/api/parameters/`, JSON.stringify(this.confirm_params));
            if (res.data.error) {
                notify('error', res.data.error, 0);
                return;
            }
            notify('success', '修改成功', 900);
            this.init_data();
        },
        async inputSwitchDialog(d) {
            const res = this.stringToList(this.params[d.name]);
            const data = await this.$refs.inputSwitchList.show({
                title: d.label,
                opts: ['*'].concat(JSON.parse(JSON.stringify(d.args.opts))),
                default: res.default,
                type_opts: ['<=>', '-->'],
                data: res.data,
            });
            this.params[d.name] = this.listToString(data.value, data.default);
            this.confirm_params[d.id] = this.listToJson(data.value, data.default);
        },
        listToString(data, val) {
            if (Array.isArray(data)) {
                const d = [`默认值:${val}`];
                for (let i = 0; i < data.length; i++) {
                    d.push(`${data[i]['opt1']}${data[i]['type']}${data[i]['opt2']}:${data[i]['value']}`);
                }
                return [...new Set(d)].join(',');
            } else {
                return data;
            }
        },
        listToJson(data) {
            if (Array.isArray(data)) {
                const d = {};
                for (let i = 0; i < data.length; i++) {
                    d[`${data[i]['opt1']}${data[i]['type']}${data[i]['opt2']}`] = data[i]['value'];
                }
                return d;
            } else {
                return {
                    'default': data,
                };
            }
        },
        stringToJson(str) {
            const json = {};
            const data = str.split(',');
            for (let i = 0; i < data.length; i++) {
                const d = data[i].split(':');
                json[d[0].replace('默认值', 'default')] = parseInt(d[1]);
            }
            return json;
        },
        stringToList(str) {
            if (typeof str === 'number') {
                return [{
                    'opt1': '*',
                    'type': '<=>',
                    'opt2': '*',
                    'value': str,
                }];
            }
            if (typeof str !== 'string') {
                return [];
            }
            const data = str.split(',');
            const res = [];
            let default_value = 0;
            for (let i = 0; i < data.length; i++) {
                const d = data[i].split(':');
                if (d[0] !== '默认值') {
                    if (d[0].includes('<=>')) {
                        res.push({
                            'opt1': d[0].split('<=>')[0],
                            'type': '<=>',
                            'opt2': d[0].split('<=>')[1],
                            'value': d[1],
                        });
                    }
                    if (d[0].includes('-->')) {
                        res.push({
                            'opt1': d[0].split('-->')[0],
                            'type': '-->',
                            'opt2': d[0].split('-->')[1],
                            'value': d[1],
                        });
                    }
                } else {
                    default_value = d[1];
                }
            }
            return {
                'default': default_value,
                'data': res,
            };
        },
        jsonToString(json) {
            console.log(json);
            if (typeof json === 'object') {
                const res = [];
                for (const key in json) {
                    res.push(`${key.replace('default', '默认值')}:${json[key]}`);
                }
                return res.join(',');
            } else {
                return json;
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only --->
<style>
.param_form{
    max-height: v-bind('max_height');
    min-height: v-bind('max_height');
    margin-top: 5px;
}
.param_field {
    border: 1px solid #dddddd;
    color: #aaaaaa;
    width: 80%;
    margin: auto;
}
.param_form label{
    min-width: 200px;
    max-width: 240px;
}
</style>
