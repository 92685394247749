const defaultOptions = {
    passive: true,
    capture: false,
};

const getDefaultPassiveOption = (passive) => {
    if (passive !== undefined) return passive;

    return defaultOptions.passive;
};

const getWritableOptions = (options) => {
    const passiveDescriptor = Object.getOwnPropertyDescriptor(options, 'passive');

    return passiveDescriptor && passiveDescriptor.writable !== true && passiveDescriptor.set === undefined
        ? Object.assign({}, options)
        : options;
};

const overwriteAddEvent = (superMethod, info) => {
    EventTarget.prototype.addEventListener = function(type, listener, options) {
        const usesListenerOptions = typeof options === 'object' && options !== null;
        const useCapture = usesListenerOptions ? options.capture : options;

        options = usesListenerOptions ? getWritableOptions(options) : {};
        options.passive = getDefaultPassiveOption(options.passive, type);
        options.capture = useCapture === undefined ? defaultOptions.capture : useCapture;
        // type,与传入的eventName一样,则覆盖event的性质
        if (type === info.eventName) {
            options.passive = info.passive;
            options.capture = info.capture;
        }
        superMethod.call(this, type, listener, options);
    };
    EventTarget.prototype.addEventListener._original = superMethod;
};

const setPassive = ({eventName, passive, capture}) => {
    const addEvent = EventTarget.prototype.addEventListener;
    overwriteAddEvent(addEvent, {eventName, passive, capture});
};

export default {setPassive};
export {setPassive};
