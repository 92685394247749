<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="40%"
        align-center
        class="range-dialog"
        @keyup.enter="doConfirm">
        <el-form :inline="false">
            <el-form-item label="默认值">
                <el-input v-model="dic.default" />
            </el-form-item>
        </el-form>
        <el-table
            ref="elTable"
            :data="data"
            :border="true">
            <el-table-column type="index" align="center" label="" width="30" />
            <el-table-column label="选项1" prop="opt1" header-align="center" align="center">
                <template #default="props">
                    <el-select
                        v-model="props.row['opt1']"
                        autocomplete="off">
                        <el-option
                            v-for="(v, k) in opts"
                            :key="k"
                            :label="v"
                            :value="v" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="类型" prop="type" header-align="center" align="center">
                <template #default="props">
                    <el-select
                        v-model="props.row['type']"
                        autocomplete="off">
                        <el-option
                            v-for="(v, k) in type_opts"
                            :key="k"
                            :label="v"
                            :value="v" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="选项2" prop="opt2" header-align="center" align="center">
                <template #default="props">
                    <el-select
                        v-model="props.row['opt2']"
                        autocomplete="off">
                        <el-option
                            v-for="(v, k) in opts"
                            :key="k"
                            :label="v"
                            :value="v" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="时长" prop="value" header-align="center" align="center">
                <template #default="props">
                    <el-input
                        v-model="props.row['value']"
                        autocomplete="off"
                        clearable />
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="" width="80" align="center">
                <template #header>
                    <el-button
                        class="btn-add"
                        @click="add_col()">
                        <el-icon><plus /></el-icon>
                    </el-button>
                </template>
                <template #default="props">
                    <el-tooltip content="删除" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            @click="del_col($event, props.$index)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <footer>
            <el-button @click="visibled = false">取 消</el-button>
            <el-button type="primary" @click="doConfirm">确 定</el-button>
        </footer>
    </el-dialog>
</template>

<script>
import {notify} from '../assets/js/utils.js';
import {Plus, Delete} from '@element-plus/icons-vue';
export default {
    name: 'InputSwitchList',
    components: {
        Plus,
        Delete,
    },
    props: {
    },
    data() {
        return {
            data: [],
            opts: [],
            dic: {
                default: 0,
            },
            type_opts: [],
            visibled: false,
            resolve: null,
            reject: null,
            title: '',
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    mounted() {
    },
    methods: {
        async show(options) {
            this.visibled = true;
            this.title = options.title;
            this.opts = options.opts;
            this.type_opts = options.type_opts;
            this.data = options.data;
            this.dic.default = options.default;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        doConfirm() {
            if (!this.checked()) {
                return;
            }
            this.resolve({
                'default': this.dic.default,
                'value': this.data,
            });
            this.reject = null;
            this.visibled = false;
        },
        checked() {
            let flag = true;
            if (!this.dic.default) {
                flag = false;
                notify('error', `${this.title}的默认值不能是空！`, 0);
                return flag;
            }
            if (!Number.isInteger(parseInt(this.dic.default))) {
                flag = false;
                notify('error', `${this.title}的默认值必须是数字！`, 0);
                return flag;
            }
            for (let i = 0; i < this.data.length; i++) {
                const r = this.data[i];
                if (!Number.isInteger(parseInt(r['value']))) {
                    flag = false;
                    notify('error', `${this.title}第${i + 1}行的时长不是数字！`, 0);
                    break;
                }
                if (!(r['opt1'] && r['opt2'] && r['type'] && r['value'])) {
                    flag = false;
                    notify('error', `${this.title}第${i + 1}行的值不能为空！`, 0);
                    break;
                }
            }
            for (let i = 0; i < this.data.length; i++) {
                const str = `${this.data[i]['opt1']}${this.data[i]['type']}${this.data[i]['opt2']}`;
                for (let j = i + 1; j < this.data.length; j++) {
                    const str2 = `${this.data[j]['opt1']}${this.data[j]['type']}${this.data[j]['opt2']}`;
                    if (str === str2) {
                        flag = false;
                        notify('error', `${this.title}第${i + 1}行和${j + 1}行的选项重复！`, 0);
                        break;
                    }
                }
                if (!flag) {
                    break;
                }
            }
            return flag;
        },
        add_col() {
            this.data.push({});
        },
        del_col(_, index) {
            this.data.splice(index, 1);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
