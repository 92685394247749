<template>
    <div class="api-test">
        <h1>SCHD PC</h1>
    </div>
    <el-button @click="get">GET MACHINES</el-button>
    <el-button @click="post">POST CALC</el-button>
    <el-button @click="ping">PING</el-button>
    <el-button @click="count_down">COUNT DOWN</el-button>
    <el-button @click="task_cancel">TASK CANCEL</el-button>
    <textarea
        ref="logref"
        :value="logs"
        style="width: 100%; height: 50%; min-height: 300px" />
    <el-input v-model="post_url" placeholder="POST URL" />
    <textarea
        v-model="post_data" style="width: 100%; height: 20%; min-height: 300px"
        placeholder="POST DATA" />
</template>

<script>
import {ComputingStore} from '../stores/computing.js';
import {LoggerStore} from '../stores/loggers.js';
import {asleep} from '../assets/js/utils.js';
import UserStore from '../stores/user.js';
import TESTDATA from '../assets/js/test-data.js';
export default {
    name: 'APITEST',
    data() {
        console.log(TESTDATA);
        const user = UserStore().user;
        const loggerstore = LoggerStore();
        const type = `msg${user.corp_id}`;
        const logger = loggerstore.create(type);
        logger.onsuccess = msg => { console.log('onsuccess', msg); };
        logger.onfailure = msg => { console.log('onsuccess', msg); };
        return {
            result: null,
            post_data: JSON.stringify(TESTDATA, null, 2),
            post_url: '/api/c/pc/',
            computing: ComputingStore(),
            sseclient: null,
            loggerstore,
            logger,
            type,
            lastest_task: null,
        };
    },
    computed: {
        logs() {
            return (this.logger?.cache ?? []).map(x => x.message).join('');
        },
    },
    watch: {
        async logs() {
            await asleep(0.1);
            this.$refs.logref.scrollTop = this.$refs.logref.scrollHeight;
        },
    },
    mounted() {
        this.logger.connect(true);
    },
    unmount() {
        this.logger.disconnect();
    },
    beforeUnmount() {
        // console.log('disconnect', this.sseclient);
        // this.sseclient.disconnect();
    },
    methods: {
        get: async function() {
            // this.computing.watch('pc');
            try {
                const url = '/api/machines/';
                const res = await this.axios.get(url);
                this.result = JSON.stringify(res.data, null, '  ');
            } catch (exc) {
                console.error(exc);
            }
        },
        post: async function() {
            try {
                const url = this.post_url;
                const res = await this.axios.post(url, this.post_data);
                this.result = JSON.stringify(res.data, null, '  ');
                this.logger.cache.push({...res.data, 'message': `${res.data.message}\n`});
            } catch (exc) {
                console.error(exc);
            }
        },
        ping: async function() {
            try {
                const url = '/api/ping';
                const data = {
                    'name': 'ping',
                    'type': this.type,
                    'args': ['-c', '9', '127.0.0.1'],
                };
                const res = await this.axios.post(url, data);
                console.log(res.data);
            } catch (exc) {
                console.error(exc);
            }
        },
        count_down: async function() {
            try {
                const url = '/api/command';
                const data = {
                    'name': 'test',
                    'type': this.type,
                    'args': [9, 0.3],
                };
                const res = await this.axios.post(url, data);
                this.lastest_task = res.data;
                console.log(res.data);
            } catch (exc) {
                console.error(exc);
            }
        },
        task_cancel: async function() {
            console.log(this.lastest_task);
            const url = `/api/tasks/${this.lastest_task.task_id}`;
            const data = {};
            const res = await this.axios.put(url, data);
            console.log(res);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
