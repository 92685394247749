<template>
    <fieldset>
        <legend>
            工序参数
        </legend>
        <el-form :model="data" label-width="80" class="info-tags">
            <el-form-item
                v-for="(param, index) in data"
                :key="index"
                :label="param.split(':')[0] + ':'"
                class="info-item">
                <span>{{ param.split(':')[1] }}</span>
            </el-form-item>
        </el-form>
    </fieldset>
</template>

<script>
export default {
    components: {
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        format_tag_values(values) {
            if (!Array.isArray(values)) return [];
            return values.map(x => x || '<空>');
        },
    },
};
</script>
<style scoped>
fieldset {
    border-color: darkgray;
}
.info-tags {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 48px);
}
.info-tags > .info-item {
    width: 25%;
    height: 24px;
}
.info-tags > .info-item {
    margin-bottom: 0;
}
.info-tags > div.info-item:last-of-type {
    flex-grow: 111111;
}
.info-tags > .info-item > button {
    margin-right: 0;
}
.info-item .el-button + .el-button {
    margin-left: 0;
}

.info-item span + .el-button {
    margin-left: 20px;
}
</style>
