<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="80%"
        height="70%"
        align-center
        class="corp-editor"
        @keyup.enter="doConfirm">
        <el-form
            ref="proform"
            :model="corp_data"
            class="corp_form data_form"
            :rules="cal_rules"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="编码" class="label_style" prop="code">
                        <el-input
                            v-model="corp_data.code"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            :disabled="disabled"
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="名称" class="label_style" prop="name">
                        <el-input
                            v-model="corp_data.name"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="有效期" class="label_style" prop="valid_date">
                        <el-date-picker v-model="corp_data.valid_date" type="date" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="ERP数据库" class="label_style" prop="colorprint_db">
                        <el-input
                            v-model="corp_data.colorprint_db"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item v-if="corp_data.id" label="状态" class="label_style" prop="state">
                        <el-select
                            ref="categorySelect"
                            v-model="corp_data.state"
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option label="活跃" value="1" />
                            <el-option label="冻结" value="-1" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="account_div">
            <fieldset v-if="disabled" class="info-box accounts">
                <legend>
                    企业账号
                </legend>
                <el-table
                    ref="elTable"
                    class="account_table"
                    :data="users"
                    :border="true">
                    <el-table-column fixed type="index" align="center" />
                    <el-table-column label="账号" prop="username" header-align="center" align="center" />
                    <el-table-column label="邮箱" prop="email" header-align="center" align="center" />
                    <el-table-column label="角色" prop="role_names" header-align="center" align="center" />
                    <el-table-column
                        label="创建时间"
                        prop="create_datetime"
                        header-align="center"
                        align="center" />
                    <el-table-column fixed="right" label="" width="100" align="center">
                        <template #header>
                            <el-button
                                v-if="false"
                                class="btn-add"
                                @click="add_user()">
                                <el-icon><plus /></el-icon>
                            </el-button>
                        </template>
                        <template #default="data">
                            <el-tooltip content="修改" placement="top">
                                <el-button
                                    link
                                    type="primary"
                                    size="large"
                                    @click="edit_user($event, data.row)">
                                    <el-icon><Edit /></el-icon>
                                </el-button>
                            </el-tooltip>
                            <el-tooltip content="删除" placement="top">
                                <el-button
                                    link
                                    type="primary"
                                    size="large"
                                    @click="del_user($event, data.$index)">
                                    <el-icon><delete /></el-icon>
                                </el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </fieldset>
        </div>
        <div class="foot_div">
            <el-row>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        @click="visibled = false">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        :disabled="!validate"
                        type="primary"
                        @click="doConfirm">
                        保存
                    </el-button>
                </el-col>
                <el-col :span="4" />
            </el-row>
        </div>
    </el-dialog>
    <userOpt ref="userOpt" />
</template>

<script>
import {notify} from '../assets/js/utils.js';
import userOpt from './user-opt.vue';
import {Plus, Delete, Edit} from '@element-plus/icons-vue';
export default {
    name: 'CorporationOpt',
    components: {
        Delete,
        Plus,
        Edit,
        userOpt,
    },
    props: {
    },
    data() {
        return {
            disabled: false,
            height: document.body.clientWidth,
            corp_data: {},
            visibled: false,
            resolve: null,
            reject: null,
            title: '企业修改',
            corp_list: [],
            users: [],
            cal_rules: {
                code: [{required: true, message: '请输入企业编码', trigger: ['change', 'blur']}],
                name: [{required: true, message: '请输入企业名称', trigger: ['change', 'blur']}],
            },
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    mounted() {
    },
    methods: {
        async show(options) {
            this.visibled = true;
            this.title = options.title;
            this.corp_list = options.corp_list;
            if (options.data) {
                this.disabled = true;
                this.corp_data = options.data;
                this.corp_data.state = this.corp_data.state.toString();
                this.corp_data.valid_date = new Date(this.corp_data.valid_date);
                this.users = this.corp_data.users;
            } else {
                this.disabled = false;
                this.corp_data = {};
                this.users = [];
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm() {
            try {
                if (!(await this.$refs.proform?.validate())) {
                    return;
                }
                if (!this.checked()) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            let success = true;
            let message = '提交失败';
            const data = {
                code: this.corp_data.code,
                name: this.corp_data.name,
                valid_date: this.corp_data.valid_date.toISOString().slice(0, 10),
                colorprint_db: this.corp_data.colorprint_db,
                state: this.corp_data.state || 1,
            };
            const url = `/api/corporations/${this.corp_data.id || ''}`;
            const res = await this.axios.post(url, JSON.stringify(data));
            if (res.data.error) {
                success = false;
                message = res.data.error;
            }
            if (!success) {
                notify('error', message, 0);
                return;
            }
            for (let j = 0; j < this.corp_data.users?.length; j++) {
                let del_flag = true;
                for (let i = 0; i < this.users.length; i++) {
                    if (this.corp_data.users[j]['id'] === this.users[i]['id']) {
                        del_flag = false;
                        break;
                    }
                }
                if (del_flag) {
                    await this.axios.delete(`/api/users/${this.corp_data.users[j]['id']}`);
                }
            }
            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i]['password']) {
                    const user_data = {
                        ...this.users[i],
                        corp_code: this.corp_data.code,
                    };
                    const user_url = `/api/users/`;
                    const u_res = await this.axios.post(user_url, JSON.stringify(user_data));
                    if (u_res.data.error) {
                        success = false;
                        message = u_res.data.error;
                        break;
                    }
                }
            }
            if (!success) {
                notify('error', message, 0);
                return;
            }
            this.resolve(data);
            // event confirm
            // this.$emit('confirm', data);
            // disable reject
            this.reject = null;
            // close dialog
            this.visibled = false;
        },
        checked() {
            let flag = true;
            for (let i = 0; i < this.corp_list.length; i++) {
                if (this.corp_list[i]['id'] !== this.corp_data.id
                && this.corp_list[i]['code'] === this.corp_data.code) {
                    notify('error', '企业代码重复！', 0);
                    flag = false;
                    break;
                }
                if (this.corp_list[i]['id'] !== this.corp_data.id
                && this.corp_list[i]['name'] === this.corp_data.name) {
                    notify('error', '企业名称重复！', 0);
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        async add_user() {
            const data = await this.$refs.userOpt.show({
                title: '账号添加',
                confirm_flag: false,
                data: {
                    corp_name: this.corp_data.name,
                },
                user_list: this.users,
            });
            if (data) {
                this.users.push(data);
            }
        },
        async edit_user(_, row) {
            const data = await this.$refs.userOpt.show({
                title: '账号修改',
                confirm_flag: false,
                data: JSON.parse(JSON.stringify({
                    ...row,
                    corp_code: this.corp_data.code,
                    corp_name: this.corp_data.name,
                })),
                user_list: this.users,
            });
            if (data) {
                for (let i = 0; i < this.users.length; i++) {
                    if (data.id === this.users[i].id) {
                        this.users[i] = data;
                        break;
                    }
                }
            }
        },
        async del_user(_, index, row) {
            this.$confirm('是否删除该账号', '删除提示', {
                iconClass: 'el-icon-question', // 自定义图标样式
                confirmButtonText: '确认', // 确认按钮文字更换
                cancelButtonText: '取消', // 取消按钮文字更换
                showClose: true, // 是否显示右上角关闭按钮
                type: 'warning', // 提示类型  success/info/warning/error
            }).then(async () => {
                if (row.id) {
                    const url = `/api/corporations/${row.id}`;
                    await this.axios.delete(url);
                }
                this.users.splice(index, 1);
                notify('success', '删除成功', 0);
            }).catch(() => {
            });
        },
        date_format(before_date) {
            const date = new Date(before_date);
            const diff_hour = date.getTimezoneOffset() / 60;
            const new_date = date.setHours(date.getHours() - diff_hour);
            return new Date(new_date).toISOString().slice(0, 10);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.corp-editor{
    position: relative !important;
    width: 90% !important;
    height: 100vh;
    max-height: 1200px;
    max-width: 1600px;
    min-height: 600px;
    min-width: 800px;
}
.corm_form{
    height: 100px;
}

.account_div {
    height: calc(100% - 283px) !important;
    position: absolute;
    top: 200px;
    bottom: 53px;
    width: 100%;
    left: 0px;
    padding: 2px 16px 2px 16px
}
.accounts {
    width: calc(100% - 56px) !important;
    height: 100% !important;
}
.account_table {
    height: 100% !important;
}
.el-date-editor.el-input{
    width: 100% !important;
    height: auto !important;
}
fieldset.info-box {
    border: 1px solid #dddddd;
    color: #aaaaaa;
}
fieldset.info-box.accounts > .info-item {
    min-width: 150px;
    width: 8%;
}
fieldset.info-box > form {
    width: 100%;
}
legend{
    text-align: left;
}
</style>
