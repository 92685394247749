const TEST_DATA = {
    'categories': [
        'print',
    ],
    'options': {
        '开始日期': '2024-05-01',
        '最小时长': 10,
    },
    'machines': {
        'MP1': {
            'last_code': null,
        },
        'MP2': {
            'last_code': null,
        },
    },
    'jobs': [
        {
            'code': 'S001-p',
            'prev_code': null,
            'product_code': 'S001',
            'process_name': '印刷',
            'quantity': 1350,
            'earliest': '2024-05-02',
            'latest': '2024-05-29',
        },
        {
            'code': 'S002-p',
            'prev_code': null,
            'product_code': 'S002',
            'process_name': '印刷',
            'quantity': 10000,
            'earliest': '2024-05-01',
            'latest': '2024-05-29',
        },
    ],
    'locks': null,
};

export default TEST_DATA;
