<template>
    <div class="main_div">
        <el-form
            :model="args"
            class="search_form">
            <el-row>
                <el-col :span="15" />
                <el-col :span="6">
                    <el-form-item label="" prop="corp">
                        <el-input
                            v-model="args.product"
                            autocomplete="off"
                            placeholder="名称或编码"
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="2">
                    <el-button
                        type="primary"
                        @click="init_data">
                        <el-icon><Search /></el-icon>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table
            ref="elTable"
            class="corp_list"
            :data="corp_data.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id"
            :expand-row-keys="expand_row_keys"
            @expand-change="on_expand_change">
            <el-table-column fixed type="expand">
                <template #default="props">
                    <div>
                        <tabUser :data="props.row.users" :corp="props.row" class="sub-table" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed type="index" align="center" />
            <el-table-column
                v-for="(col, i) in tableCols"
                :key="i"
                :label="col.label"
                v-bind="col"
                header-align="center"
                :align="col.align" />
            <el-table-column fixed="right" label="" width="120" align="center">
                <template #header>
                    <el-button
                        class="btn-add"
                        @click="add_corp()">
                        <el-icon><plus /></el-icon>
                    </el-button>
                </template>
                <template #default="data">
                    <el-tooltip content="编辑企业" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click="edit_corp($event, data.row)">
                            <el-icon><edit /></el-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="删除企业" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click="del_corp($event, data.row)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="添加账号" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click="add_user($event, data.row)">
                            <el-icon><plus /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            align="center"
            class="page"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="corp_data.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
    </div>
    <corporationOpt ref="corporationOpt" />
    <userOpt ref="userOpt" />
</template>
<script>
import {notify} from '../assets/js/utils.js';
import tabUser from './tab-user.vue';
import corporationOpt from './corporation-opt.vue';
import userOpt from './user-opt.vue';
import {Plus, Search, Delete, Edit} from '@element-plus/icons-vue';
export default {
    name: 'CorporationList',
    components: {
        tabUser,
        corporationOpt,
        Plus,
        Search,
        Delete,
        Edit,
        userOpt,
    },
    data: function() {
        return {
            args: {
                product: '',
            },
            expand_row_keys: [],
            corp_data: [],
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 10, // 每页的数据条数
        };
    },
    computed: {
        tableCols() {
            return [
                {
                    prop: 'code',
                    label: '编码',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'name',
                    label: '名称',
                    align: 'center',
                },
                {
                    prop: 'valid_date',
                    label: '有效期',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'colorprint_db',
                    label: 'ERP数据库',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'state_name',
                    label: '状态',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'created_at_show',
                    label: '创建时间',
                    width: 250,
                    align: 'center',
                },
            ];
        },
    },
    mounted() {
        this.init_data();
    },
    methods: {
        on_expand_change(row, children) {
            const key = row.id;
            const index = this.expand_row_keys.indexOf(key);
            if (index < 0) {
                this.expand_row_keys.push(key);
            } else {
                this.expand_row_keys.splice(index, 1);
            }
        },
        async add_user(_, row) {
            const data = await this.$refs.userOpt.show({
                title: '账号添加',
                data: {
                    corp_id: row.id,
                    corp_name: row.name,
                    corp_code: row.code,
                    roles: [],
                },
                user_list: row.users,
            });
            if (data) {
                this.init_data();
            }
        },
        async add_corp() {
            const data = await this.$refs.corporationOpt.show({
                title: '企业添加',
                data: null,
                corp_list: this.corp_data,
            });
            if (data) {
                this.init_data();
            }
        },
        async del_corp(_, row) {
            this.$confirm('是否删除该企业', '删除提示', {
                iconClass: 'el-icon-question', // 自定义图标样式
                confirmButtonText: '确认', // 确认按钮文字更换
                cancelButtonText: '取消', // 取消按钮文字更换
                showClose: true, // 是否显示右上角关闭按钮
                type: 'warning', // 提示类型  success/info/warning/error
            }).then(async () => {
                const url = `/api/corporations/${row.id}`;
                const res = await this.axios.delete(url);
                if (res.data.result) {
                    notify('success', '删除成功', 0);
                } else {
                    notify('error', res.data.error, 0);
                }
                this.init_data();
            }).catch(() => {
            });
        },
        async edit_corp(_, row) {
            const data = await this.$refs.corporationOpt.show({
                title: '企业修改',
                data: JSON.parse(JSON.stringify(row)),
                corp_list: this.corp_data,
            });
            if (data) {
                this.init_data();
            }
        },
        async init_data() {
            const url = `/api/corporations/`;
            let args = '';
            if (this.args.corp) {
                args = `?q=${this.args.corp}`;
            }
            const res = await this.axios.get(url + args);
            const corp_data = res.data.data;
            for (let i = 0; i < corp_data.length; i++) {
                corp_data[i]['created_at_show'] = corp_data[i]['created_at'].replace('T', ' ');
                if (corp_data[i]['state'] === 1) {
                    corp_data[i]['state_name'] = '活跃';
                } else {
                    corp_data[i]['state_name'] = '冻结';
                }
                corp_data[i]['users'] = await this.getUsers(corp_data[i]['id']);
            }
            this.corp_data = corp_data;
        },
        async getUsers(corp_id) {
            const url = `/api/users/?c=${corp_id}`;
            const res = await this.axios.get(url);
            const users = res.data.data;
            for (let i = 0; i < users.length; i++) {
                const role_names = users[i].roles?.reduce((res, data) => {
                    res.push(data.name);
                    return res;
                }, []) || [];
                users[i]['role_names'] = role_names.join(',');
                users[i]['create_datetime'] = users[i]['create_datetime'].replace('T', ' ');
            }
            return users;
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_div{
    position: relative;
    width: 100%;
    height: 100%;
}
.corp_list{
    position: absolute;
    top:50px;
    bottom: 60px;
    height: calc(100% - 110px);
}
.search_form {
    height:50px;
}
.page{
    position: absolute;
    height: 50px;
    bottom: 10px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
</style>
