import {createApp} from 'vue';
import App from '@/App.vue';
import router from '@/router.js';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'dayjs/locale/zh-cn.js';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSSE from 'vue-sse';
import utils from './assets/js/utils.js';
// import mqtt from './assets/js/mqtt.js';
import intervals from './assets/js/intervals.js';
import {createPinia} from 'pinia';
// import piniaPluginPersisted from 'vue3-persist-storages';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// import zhCn from 'element-plus/lib/locale/zh-cn.js';
import zhCn from 'element-plus/dist/locale/zh-cn.js';
import Modal from './assets/js/dzmodal.js';
import 'default-passive-events';

// import VueMqtt from 'vue-mqtt';
// Vue.use(VueMqtt, 'ws://iot.eclipse.org/ws', options);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env?.VUE_APP_API_URL;

const VUE_APP_SSE_URL = process.env?.VUE_APP_SSE_URL ?? `${axios.defaults.baseURL}/stream/`;

createApp(App)
    .use(router)
    .use(createPinia().use(piniaPluginPersistedstate))
    .use(utils)
    .use(intervals)
    .use(ElementPlus, {locale: zhCn})
    .use(VueAxios, axios)
    .use(VueSSE, {
        // format: 'json',
        polyfill: true,
        url: VUE_APP_SSE_URL,
        withCredentials: true,
    })
    .use(Modal)
    // .use(mqtt)
    .mount('#app');
