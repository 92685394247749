<template>
    <!-- <div class="schd-pc">
        <h1>SCHD PC</h1>
    </div>
    <textarea :value="result" style="width: 100%; height: 50%; min-height: 300px" /> -->
    <ExcelDisplay />
</template>

<script>
import {ComputingStore} from '../stores/computing.js';
import ExcelDisplay from './excel-display.vue';

export default {
    name: 'SCHDPC',
    components: {
        ExcelDisplay,
    },
    data() {
        return {
            result: null,
            computing: ComputingStore(),
        };
    },
    methods: {
        test: async function() {
            const _code = 'simple';
            const _name = '产品样本';
            const _complex_type = ['干法', '无溶剂'];
            const _glue = ['普通', '蒸煮', '水煮'];
            const obj = {
                code: 'simple',
                name: '产品样本',
                spec: '(210+38*2)*160mm',
                stru: 'OPP19/AL7/PET12/乳白PE40',
                desc: '直立拉链袋',
                data: {
                    '用途': '食品',
                    '工艺': [
                        {
                            'cate_code': 'print',
                            'cate_name': '印刷',
                            'name': '印刷',
                            'last_process': [],
                            'structure': 'PET/OPP',
                            'standard': '(210+38*2)*160mm',
                            'unit': '米',
                            'output_ratio': 1,
                            '色序': [
                                'A',
                                'DD',
                                'EE',
                                'DE',
                                'SF',
                                'AF',
                            ],
                            '光油': '无',
                            '材质': 'PET',
                            '印面': '表印',
                            '幅宽': 210,
                        },
                        {
                            'cate_code': 'complex',
                            'cate_name': '复合',
                            'name': '复合1',
                            'last_process': [
                                '印刷',
                            ],
                            'structure': 'PET/OPP',
                            'standard': '(210+38*2)*160mm',
                            'unit': '米',
                            'output_ratio': 1,
                            '类型': '干法',
                            '胶水': '普通',
                            '复次': '一复',
                        },
                        {
                            'cate_code': 'mkbag',
                            'cate_name': '制袋',
                            'name': '制袋',
                            'last_process': [
                                '复合1',
                            ],
                            'structure': 'PET/OPP',
                            'standard': '(210+38*2)*160mm',
                            'unit': '个',
                            'output_ratio': 4,
                        },
                    ],

                },
            };
            const url = `/api/products/`;
            for (let i = 1; i < 66; i++) {
                obj.name = `${String.fromCharCode(64 + i % 26)}${_name}${i}`;
                obj.code = `${String.fromCharCode(64 + i % 26)}${_code}${i}`;
                obj.data['工艺'].forEach((item) => {
                    if (item.cate_code === 'complex') {
                        item['类型'] = _complex_type[i % 2];
                        item['胶水'] = _glue[i % 3];
                    }
                });
                await this.axios.post(url, JSON.stringify(obj));
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
