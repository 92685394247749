/* eslint no-mixed-operators: 0 */
import dayjs from 'dayjs';
// import jsonpath from 'jsonpath';
import {JSONPath as jpp} from 'jsonpath-plus';
import {ElNotification} from 'element-plus';

function uuid4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
}
const zip = (...arr) =>
    Array(Math.max(...arr.map(a => a.length)))
        .fill()
        .map(vi => arr.map(a => a[vi[1]]));

const max = (...arr) => arr.length && arr.reduce((a, b) => (a < b ? b : a));

const min = (...arr) => arr.length && arr.reduce((a, b) => (a > b ? b : a));

const sum = (...arr) => (arr.length ? arr.reduce((r, x) => r + x, 0) : NaN);

const cmp = (a, b) => (a === b ? 0 : a > b ? 1 : -1);

const map_keys = m => (typeof m === 'object' ? Object.entries(m).map(x => x[0]) : []);

const map_values = m => (typeof m === 'object' ? Object.entries(m).map(x => x[1]) : []);

const jsonquery = (json, path) => {
    // use jsonpath
    // return jsonpath.query(obj, path);
    // use jsonpath-plus
    return jpp({json, path});
};

const jsonone = (json, path) => {
    const lst = jsonquery(json, path);
    return lst.length ? lst[0] : undefined;
};

/*********************************
 * 仿python itertools.groupby
 **/

function * groupby(arr, fkey, sort) {
    const iter = (function * () {
        yield * sort ? arr.sort((a, b) => cmp(fkey(a), fkey(b))) : arr;
    })();

    let item = iter.next();
    while (!item.done) {
        const key = fkey(item.value);
        yield [
            key,
            [
                ...(function * () {
                    do {
                        yield item.value;
                    } while (!(item = iter.next()).done && fkey(item.value) === key);
                })(),
            ],
        ];
    }
}

/*********************************
 * 将数组转换成 group map
 * arr => {key, [value]}
 **/
const gmap = (iter, key, value) => {
    if (typeof key !== 'function') {
        const k = key;
        key = key === undefined ? x => x : x => x[k];
    }
    if (typeof value !== 'function') {
        const v = value;
        value = value === undefined ? x => x : x => x[v];
    }
    if (typeof key === 'function') {
        const f = (r, xv, xk) => {
            const k = key(xv, xk);
            if (r[k] === undefined) {
                r[k] = [value(xv, xk)];
            } else {
                r[k].push(value(xv, xk));
            }
            return r;
        };
        if (iter instanceof Array) {
            return iter.reduce((r, x, i) => {
                return f(r, x, i);
            }, {});
        }
        if (typeof iter === 'object') {
            return Object.entries(iter).reduce((r, [xk, xv]) => {
                return f(r, xv, xk);
            }, {});
        }
        throw Error(`Unknown paramter 0, type ${typeof iter}, ${iter}`);
    }
    throw Error(`Unknown param key <${typeof key} ${key}>`);
};

const fmtDD = value => (value instanceof dayjs ? value.format('YYYY/MM/DD') : value);

const fmtDM = value => (value instanceof dayjs ? value.format('YYYY/MM/DD HH:mm') : value);

const fmtDT = value => (value instanceof dayjs ? value.format('YYYY/MM/DD HH:mm:ss') : value);

// 格式化分钟为可读时长
function fmtDRM(value) {
    return !Number.isInteger(value)
        ? null
        : value < 60
            ? `${value}'`
            : value % 60 === 0
                ? `${value / 60}h`
                : `${parseInt(value / 60)}h${value % 60}'`;
}

/********************************
 * Object copy by keys
 * @obj: object for copy
 * @keys: keys for copy
 * @ignores: exclude value for copy
 ********************************/
// 单层拷贝
function objcopy(obj, keys, ignores) {
    ignores = new Set(Array.isArray(ignores) ? ignores : [undefined, null]);
    if (!keys?.length) {
        return Object.fromEntries(Object.entries(obj).filter(kv => !ignores.has(kv[1])));
    }
    keys = new Set(keys);
    return Object.fromEntries(
        Object.entries(obj).filter(([k, v]) => keys.has(k) && !ignores.has(v)),
    );
}
// 多层拷贝
// eg: keys = [a, b, {c: [m, n], d: [x, y]}]
function dictcopy(obj, keys, ignores) {
    if (typeof obj !== 'object') return null;
    ignores = new Set(Array.isArray(ignores) ? ignores : [undefined, null]);
    if (!keys?.length) {
        return Object.fromEntries(Object.entries(obj).filter(kv => !ignores.has(kv[1])));
    }
    return keys.reduce((r, k) => {
        if (k in obj) {
            if (typeof k === 'object') {
                Object.entries(k).forEach(([k, kk]) => {
                    r[k] = dictcopy(obj[k], kk, ignores);
                });
            } else {
                r[k] = obj[k];
            }
        }
        return r;
    }, {});
}

/********************************
 * async fnction ****************/
async function asleep(ms) {
    await new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

/********************************
 * @args:
 *      <string> info|warning|success|errro, 错误类型
 *      <string> 信息标题
 *      <string> 信息内容
 *      ...      信息内容
 *      <number> 展示停留时间
 **/
function notify(...args) {
    let data = {};
    const msgs = [];
    // 解析参数
    args.forEach((x, i) => {
        if (x instanceof Error) {
            data.type = 'error';
            if (!data.title) data.title = `${x}`.split(':', 1)[0];
            msgs.push(`${x}`.split(':').slice(1).join(':'));
        } else if (typeof x === 'object') {
            data = {...data, ...x};
            if (data.message) msgs.push(x);
        } else if (typeof x === 'string') {
            if (['info', 'warning', 'success', 'error'].indexOf(x) >= 0) {
                if (data.type !== 'error') data.type = x;
            } else if (!data.title) {
                data.title = x;
            } else {
                msgs.push(x);
            }
        } else if (typeof x === 'number') {
            data.duration = x;
        } else {
            console.warn(`Unknown args[${i}]`, x);
        }
    });
    if (data.type === 'error' && data.duration === undefined) data.duration = -1;
    if (data.showClose !== false) data.showClose = true;
    data.message = msgs.join('\n');
    // console.log(data);
    ElNotification(data);
}

export default {
    install(app) {
        // 在app上进行扩展，app提供 component directive 函数
        // 如果要挂载原型 app.config.globalProperties 方式
        app.config.globalProperties.$utils = {
            sum,
            max,
            zip,
            uuid4,
            fmtDD,
            fmtDM,
            fmtDT,
            jsonone,
            jsonquery,
        };
        app.config.globalProperties.$msg = notify;
    },
};

export {
    sum,
    max,
    min,
    zip,
    cmp,
    gmap,
    jsonquery,
    jsonone,
    map_values,
    map_keys,
    uuid4,
    notify,
    fmtDD,
    fmtDM,
    fmtDT,
    fmtDRM,
    groupby,
    objcopy,
    dictcopy,
    asleep,
};
