<template>
    <fieldset>
        <legend>
            生产日历
        </legend>
        <el-table
            ref="elTable"
            class="calendar_table"
            :data="data"
            height="100%"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id">
            <el-table-column fixed type="expand">
                <template #default="props">
                    <div>
                        <tabCalendarTime :data="props.row.days" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed type="index" align="center" />
            <el-table-column prop="name" label="规则名称" align="center" />
            <el-table-column prop="start" label="开始日期" align="center" />
            <el-table-column prop="days_count" label="规则周期" align="center" />
            <el-table-column prop="repeat_name" label="规则模式" align="center" />
        </el-table>
    </fieldset>
</template>

<script>
import tabCalendarTime from './tab-calendar-time.vue';
export default {
    components: {
        tabCalendarTime,
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
    },
};
</script>
<style scoped>
</style>
