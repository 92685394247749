/***
 * 动态调用模态对话框
 *
 * 加载modal(main.js)
 * import {createApp} from 'vue';
 * import Modal from './assets/js/dzmodal.js';
 * createApp(App).use(createPinia()).use(ElementPlus, {locale}).use(Modal).mount('#app');
 *
 * 对话框(dialog.vue):
 * <template>
 *   <el-dialog v-model="visibled" @keyup.enter="doConfirm">
 *   ...
 *   </el-dialog>
 * </template>
 * <script>
 * export default {
 *   emits: ['confirm', 'cancel'],
 *   data(props) {
 *      // props.options 是调用时传入的参数
 *      return {visibled: true, ...options};
 *   },
 *   methods: {
 *     doConfirm() {
 *        this.$emit('confirm', data);
 *        // hidden
 *        this.visibled = false;
 *     },
 *     doCancel() {
 *       this.$emit('cancel', data);
 *       this.visibled = false;
 *     }
 *   }
 * }
 * </script>
 *
 * 调用代码(test.vue)
 * <script>
 * import TestDialog from "./dialog.vue";
 * export default {
 *   methods: {
 *     // 调用dialog
 *     async open_dialog() {
 *       // options: Object参数
 *       const result = await this.$modal(TestDialog, options);
 *       console.log(result);
 *     }
 *   }
 * }
 * </script>
 *
 * 参考：https://www.jb51.net/article/242666.htm
 ***/
import {h, render, inject} from 'vue';
export const DynamicModalSymbol = Symbol(null);

function is_function(obj) {
    return typeof obj === 'function' && typeof obj.nodeType !== 'number';
}

export class DynamicModalService {
    constructor(app) {
        this._app = app;
    }

    open(modal, options) {
        const promise = new Promise((resolve, reject) => {
            if (!this._app) {
                reject(new Error('_app is undefined'));
                reject = undefined;
                return;
            }
            const container = document.createElement('div');
            document.body.appendChild(container); // 这里需要合并props，传入到组件modal
            const props = Object.fromEntries(
                Object.entries(options).filter(kv => is_function(kv[1])),
            );
            const data = Object.fromEntries(
                Object.entries(options).filter(kv => !is_function(kv[1])),
            );
            if (Object.entries(data).length) {
                const modal_data = modal.data;
                modal = {
                    ...modal,
                    data(props) {
                        if (!modal.data) return data;
                        props.options = data;
                        return modal_data.bind(this)(props);
                    },
                };
            }
            const vm = h(modal, {
                ...props,
                onClosed(...args) {
                    if (props.onClosed) props.onClosed(...args);
                    document.body.removeChild(container);
                    // 强制返回
                    console.debug('close...');
                    if (resolve && reject) {
                        resolve = undefined;
                        reject(new Error('cancel'));
                    }
                },
                onConfirm(...args) {
                    console.debug('confirm...', ...args);
                    if (props.onConfirm) props.onConfirm(...args);
                    reject = undefined;
                    resolve(...args);
                },
                onCancel(...args) {
                    console.debug('cancel', ...args);
                    if (props.onCancel) props.onCancel(...args);
                    if (resolve && reject) {
                        resolve = undefined;
                        reject(new Error('cancel...'));
                    }
                },
            });
            // 这里很重要，关联app上下文
            vm.appContext = this._app._context;
            render(vm, container);
        });
        return promise;
    }
}

export function useDynamicModal() {
    const modal = inject(DynamicModalSymbol);
    if (!modal) {
        throw new Error('No DModal provided!');
    }
    return modal;
}

const plugin = {
    install(app) {
        const modal = new DynamicModalService(app);
        app.config.globalProperties.$modal = modal;
        app.provide(DynamicModalSymbol, modal);
    },
};
export default plugin;
