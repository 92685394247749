<template>
    <fieldset>
        <legend>
            企业账号
        </legend>
        <el-table
            ref="elTable"
            :data="data"
            height="100%"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id">
            <el-table-column fixed type="index" align="center" />
            <el-table-column label="账号" prop="username" header-align="center" align="center" />
            <el-table-column label="邮箱" prop="email" header-align="center" align="center" />
            <el-table-column label="角色" prop="role_names" header-align="center" align="center" />
            <el-table-column label="创建时间" prop="create_datetime" header-align="center" align="center" />
        </el-table>
    </fieldset>
</template>

<script>
export default {
    components: {
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
    },
};
</script>
<style scoped>
</style>
