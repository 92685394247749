<template>
    <el-form class="login">
        <el-form-item label="帐号">
            <el-input v-model="username" placeholder="Username" />
        </el-form-item>
        <el-form-item label="密码">
            <el-input v-model="password" type="password" placeholder="Password" />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" style="margin-left: 40px" @click="login">登 录</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import UserStore from '../stores/user.js';
import {get_menu_routes} from '../router.js';

export default {
    name: 'UserLogin',
    setup() {
        const userstore = UserStore();
        return {userstore};
    },
    data: function() {
        return {
            username: '',
            password: '',
        };
    },
    methods: {
        async login() {
            const msg = await this.userstore.login(this.username, this.password);
            if (msg) {
                this.$msg('error', msg, 3000);
            } else {
                this.$router.push(get_menu_routes()[0].path);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
    position: relative;
    display: block;
    width: 400px;
    margin: 0 auto;
}
</style>
