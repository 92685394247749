<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="80%"
        height="80%"
        align-center
        class="machine-editor"
        @keyup.enter="doConfirm">
        <el-form
            ref="proform"
            :model="mac_data"
            class="machine_form data_form"
            :rules="mac_rules"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="编码" class="label_style" prop="code">
                        <el-input
                            ref="codeInput"
                            v-model="mac_data.code"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="名称" class="label_style" prop="name">
                        <el-input
                            v-model="mac_data.name"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="客户" class="label_style" prop="corp_name">
                        <el-input
                            v-model="mac_data.corp_name"
                            autocomplete="off"
                            placeholder=""
                            disabled
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="类型" class="label_style" prop="cate_id">
                        <el-select
                            ref="categorySelect"
                            v-model="mac_data.cate_id"
                            autocomplete="off"
                            placeholder="请选择"
                            @change="initParams">
                            <el-option
                                v-for="(category_data, i) in category_list"
                                :key="i"
                                :label="category_data.name"
                                :value="category_data.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="最大机速" class="label_style" prop="speed">
                        <el-input
                            v-model="mac_data.speed"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-form v-if="param_list.length>0" :model="params">
            <el-row>
                <el-col
                    v-for="(data,i) in param_list"
                    :key="i"
                    :span="6">
                    <el-form-item :label="data.name">
                        <el-input
                            v-if="data.args.style=='input'"
                            v-model="params[data.name]"
                            autocomplete="off"
                            :placeholder="data.args.detail"
                            clearable />
                        <el-select
                            v-if="data.args.style=='select' && !data.type.includes('list')"
                            v-model="params[data.name]"
                            autocomplete="off"
                            :placeholder="data.args.detail">
                            <el-option
                                v-for="(v, k) in data.args.opts"
                                :key="k"
                                :label="v"
                                :value="v" />
                        </el-select>
                        <el-select
                            v-if="data.args.style=='select' && data.type.includes('list')"
                            v-model="params[data.name]"
                            multiple
                            autocomplete="off"
                            :placeholder="data.args.detail">
                            <el-option
                                v-for="(v, k) in data.args.opts"
                                :key="k"
                                :label="v"
                                :value="v" />
                        </el-select>
                        <el-input
                            v-if="data.args.style=='range'"
                            :id="data.name"
                            v-model="params[data.name]"
                            autocomplete="off"
                            :placeholder="data.args.detail"
                            clearable
                            readonly
                            @click="inputDialog(data)" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="foot_div">
            <el-row>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        @click="visibled = false">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        :disabled="!validate"
                        type="primary"
                        @click="doConfirm">
                        保存
                    </el-button>
                </el-col>
                <el-col :span="4" />
            </el-row>
        </div>
    </el-dialog>
    <inputRangeList ref="inputRangeList" />
</template>

<script>
import {notify} from '../assets/js/utils.js';
import inputRangeList from './inputRangeList.vue';
export default {
    name: 'MachineOpt',
    components: {
        inputRangeList,
    },
    props: {
    },
    data() {
        return {
            height: document.body.clientWidth,
            mac_data: {},
            visibled: false,
            resolve: null,
            reject: null,
            title: '机台修改',
            machine_list: [],
            category_list: [],
            param_defined_list: {},
            param_list: [],
            params: {},
            mac_rules: {
                code: [{required: true, message: '请输入机台编码', trigger: ['change', 'blur']}],
                name: [{required: true, message: '请输入机台名称', trigger: ['change', 'blur']}],
                speed: [{required: true, message: '请输入最大机速', trigger: ['change', 'blur']}],
                cate_id: [{required: true, message: '请选择机台类别', trigger: ['change', 'blur']}],
            },
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    mounted() {
    },
    methods: {
        async show(options) {
            await this.initCategory();
            console.log(options);
            this.visibled = true;
            this.param_list = [];
            this.params = {};
            this.title = options.title;
            this.machine_list = options.machine_list;
            this.mac_data = options.data;
            if (this.mac_data.cate_id) {
                await this.initParams();
                for (const k in this.params) {
                    if (this.param_defined_list[k]?.type === 'range[integer]') {
                        this.params[k] = this.rangeToString(options.data.data[k]);
                    } else {
                        this.params[k] = options.data.data[k];
                    }
                }
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm() {
            this.confirmParamFormat();
            try {
                if (!(await this.$refs.proform?.validate())) {
                    return;
                }
                if (!this.checked()) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            const url = `/api/corporations/${this.mac_data.corp_id}/machines/${this.mac_data.id || ''}`;
            const data = {
                ...this.mac_data,
                data: this.confirm_params,
            };
            const res = await this.axios.post(url, JSON.stringify(data));
            if (res.data.error) {
                notify('error', res.data.error, 0);
                return;
            }
            this.resolve(data);
            // event confirm
            // this.$emit('confirm', data);
            // disable reject
            this.reject = null;
            // close dialog
            this.visibled = false;
        },
        checked() {
            let flag = true;
            if (this.mac_data.code) {
                for (let i = 0; i < this.machine_list.length; i++) {
                    if (this.mac_data.id !== this.machine_list[i]['id']
                    && this.mac_data.code.trim() === this.machine_list[i]['code'].trim()) {
                        notify('error', '机台编码重复！', 0);
                        flag = false;
                        break;
                    }
                    if (this.mac_data.id !== this.machine_list[i]['id']
                    && this.mac_data.name.trim() === this.machine_list[i]['name'].trim()) {
                        notify('error', '机台名称重复！', 0);
                        flag = false;
                        break;
                    }
                }
            }
            if (this.param_list.length > 0) {
                for (let p = 0; p < this.param_list.length; p++) {
                    const param = this.param_list[p];
                    if (!this.confirm_params[param['name']]
                        || this.confirm_params[param['name']].length === 0) {
                        notify('error', `${param['name']}内容为空！`, 0);
                        flag = false;
                        break;
                    }
                    if (param.type === 'integer') {
                        if (!Number.isInteger(this.confirm_params[param['name']])) {
                            notify('error', `${param['name']}内容必须为数字！`, 0);
                            flag = false;
                            break;
                        }
                    }
                    if (param.type === 'list[integer]') {
                        for (let i = 0; i < this.confirm_params[param['name']].length; i++) {
                            if (!Number.isInteger(this.confirm_params[param['name']][i])) {
                                notify('error', `${param['name']}内容必须为数字！`, 0);
                                flag = false;
                                break;
                            }
                        }
                        if (!flag) {
                            break;
                        }
                    }
                }
                if (!flag) {
                    return flag;
                }
            }
            return flag;
        },
        async initCategory() {
            const url = `/api/categories/`;
            const res = await this.axios.get(url);
            this.category_list = res.data.data;
        },
        async initParams() {
            const url = `/api/parameter-defineds/?t=2&c=${this.mac_data['cate_id']}`;
            const res = await this.axios.get(url);
            this.param_list = res.data.data;
            this.param_defined_list = this.param_list.reduce((res, data) => {
                res[data['name']] = data;
                return res;
            }, {});
            this.params = {};
            for (let i = 0; i < this.param_list.length; i++) {
                this.params[this.param_list[i].name] = null;
                if (this.param_list[i].type === 'range[integer]') {
                    this.params[this.param_list[i].name] = [];
                }
                if (this.param_list[i].type.includes('list')) {
                    this.params[this.param_list[i].name] = [];
                }
            }
        },
        input(event, k, v, index) {
            this.params[k] = v;
        },
        async confirmParamFormat() {
            this.confirm_params = {};
            for (let i = 0; i < this.param_list.length; i++) {
                const p = this.param_list[i];
                if (p['type'].includes('list') && p.args.style === 'input'
                    && this.params[p['name']] && !Array.isArray(this.params[p['name']])) {
                    const str = this.params[p['name']].replaceAll('，', ',');
                    this.confirm_params[p['name']] = str.split(',');
                    if (p['type'].includes('integer')) {
                        for (let j = 0; j < this.confirm_params[p['name']].length; j++) {
                            this.confirm_params[p['name']][j] = parseInt(this.confirm_params[p['name']][j]);
                        }
                    }
                    if (p['type'].includes('float')) {
                        for (let j = 0; j < this.confirm_params[p['name']].length; j++) {
                            this.confirm_params[p['name']][j] = parseFloat(this.confirm_params[p['name']][j]);
                        }
                    }
                    if (p['type'].includes('string')) {
                        for (let j = 0; j < this.confirm_params[p['name']].length; j++) {
                            this.confirm_params[p['name']][j] = this.confirm_params[p['name']][j].toString();
                        }
                    }
                } else if (p['type'] === 'range[integer]') {
                    this.confirm_params[p['name']] = this.stringToRange(this.params[p['name']]);
                } else if (p['type'] === 'integer') {
                    this.confirm_params[p['name']] = parseInt(this.params[p['name']]);
                } else if (p['type'] === 'float') {
                    this.confirm_params[p['name']] = parseFloat(this.params[p['name']]);
                } else if (p['type'] === 'string') {
                    this.confirm_params[p['name']] = this.params[p['name']]?.toString();
                } else {
                    this.confirm_params[p['name']] = this.params[p['name']];
                }
            }
        },
        async inputDialog(d) {
            const data = await this.$refs.inputRangeList.show({
                title: d.name,
                data: this.stringToRange(this.params[d.name]),
            });
            this.params[data.name] = this.rangeToString(data.value);
        },
        rangeToString(data) {
            if (Array.isArray(data)) {
                const d = JSON.parse(JSON.stringify(data));
                let flag = true;
                for (let i = 0; i < d.length; i++) {
                    if (typeof d[i] !== 'number') {
                        flag = false;
                        break;
                    }
                }
                if (d.length === 2 && flag) {
                    return d.join('~');
                } else {
                    for (let i = 0; i < d.length; i++) {
                        if (Array.isArray(d[i])) {
                            if (d[i][0] === d[i][1]) {
                                d[i] = d[i][0];
                            } else {
                                d[i] = d[i].join('~');
                            }
                        }
                    }
                    return d.join(',');
                }
            } else {
                return data;
            }
        },
        stringToRange(str) {
            if (!str) {
                return [];
            }
            const data = str.split(',');
            for (let i = 0; i < data.length; i++) {
                if (data[i].split('~').length > 1) {
                    data[i] = [
                        parseInt(data[i].split('~')[0]),
                        parseInt(data[i].split('~')[1]),
                    ];
                } else {
                    data[i] = [parseInt(data[i]), parseInt(data[i])];
                }
            }
            return data;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.machine-editor{
    height: 100% !important;
    max-height: 700px;
    max-width: 1080px;
}
.machine_form {
    margin-bottom: 10px;
}
.mac_param_form {
    margin-top: 20px;
}
</style>
