<template>
    <el-row class="query-row">
        <el-col :span="6">
            <el-input
                v-model="keyword"
                autocomplete="off"
                placeholder="任务号|产品|工序"
                clearable
                maxlength="128" />
        </el-col>
        <el-col :span="1" />
        <el-col :span="6" />
        <el-col :span="9" />
        <el-col :span="2" />
    </el-row>
    <el-row class="table-container">
        <el-table
            border
            height="100%"
            :data="schdList"
            row-key="serial_number"
            :header-cell-style="{background:'#f5f7fa',color:'#606266'}">
            <el-table-column
                align="center"
                width="40"
                label=""
                type="expand">
                <template #default="{row}">
                    <el-tag v-for="(item,i) in row.error" :key="i" type="danger">{{ item.info }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                width="60"
                label="">
                <template #default="{row,$index}">
                    <el-text class="mx-1" :type="row.error.length>0?'danger':''">
                        {{ $index+1 }}
                        <el-icon v-if="row.error.length>0"><WarningFilled /></el-icon>
                    </el-text>
                </template>
            </el-table-column>
            <el-table-column
                prop="machine"
                width="100"
                label="机台"
                :filters="getColFilters('machine')"
                :filter-method="filterCol" />
            <el-table-column
                prop="job_code"
                width="120"
                label="任务号" />
            <el-table-column
                prop="product"
                show-overflow-tooltip
                min-width="120"
                label="产品" />
            <el-table-column
                prop="process_name"
                show-overflow-tooltip
                label="工序"
                width="100"
                :filters="getColFilters('process_name')"
                :filter-method="filterCol" />
            <el-table-column
                prop="pre"
                label="准备开始" />
            <el-table-column
                prop="run"
                label="开始时间" />
            <el-table-column
                prop="end"
                label="完成时间" />
            <el-table-column
                prop="date"
                label="日期"
                :filters="getColFilters('date')"
                :filter-method="filterCol" />
            <el-table-column
                prop="remark"
                label="备注" />
            <el-table-column fixed="right" label="" width="auto" align="center">
                <template #default="scope">
                    <el-tooltip content="增加锁定" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            :disabled="forbid(scope.row)"
                            @click="addToLock(scope)">
                            <el-icon><Lock /></el-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="删除" placement="top">
                        <el-button
                            link
                            type="danger"
                            size="large"
                            @click="deleteItem(scope)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </el-row>
</template>
<script>
import SchdStore from '../stores/schdtabular.js';
import {notify} from '../assets/js/utils.js';
import {ElMessageBox} from 'element-plus';
import {Delete, Lock, WarningFilled} from '@element-plus/icons-vue';
export default {
    name: 'SchdResList',
    components: {
        Delete,
        Lock,
        WarningFilled,
    },
    props: {
        planDate: {
            type: String,
            default: '',
        },
    },
    setup() {
        const uWbStore = SchdStore();
        return {uWbStore};
    },
    data() {
        return {
            keyword: '',
        };
    },
    computed: {
        schdList() {
            const _keyword = this.keyword.trim();
            if (_keyword) {
                return this.uWbStore.schdList.filter((item) => {
                    return item.job_code.includes(_keyword) || item.product.includes(_keyword)
                    || item.process_name.includes(_keyword);
                });
            }
            return this.uWbStore.schdList;
        },
    },
    methods: {
        getColFilters(col) {
            const filters = [...new Set(this.schdList.map((item) => {
                return item[col];
            }))];
            return filters.map((item) => {
                return {
                    text: item,
                    value: item,
                };
            });
        },
        filterCol(value, row, column) {
            return row[column.property] === value;
        },
        forbid(row) {
            if (row.error.length > 0) return true;
            if (row.is_finished === '是') return true;
            return false;
        },
        deleteItem(scope) {
            ElMessageBox.confirm(
                `删除本行数据？${scope.row.job_code}`,
                '提醒',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.uWbStore.deleteSchd(scope.row.serial_number);
                    notify('success', '删除成功', 1500);
                })
                .catch((msg) => {
                    notify('info', msg === 'cancel' ? '取消' : msg, 1500);
                });
        },
        addToLock(scope) {
            const schd = this.uWbStore.getSchd(scope.row.serial_number);
            const res = this.uWbStore.beforeAddLockCheck(schd);
            if (res.isError) {
                notify('error', res.msg, 2000);
                return;
            }
            this.uWbStore.addLocks([scope.row]);
        },
    },
};
</script>
<style scoped>
/* Add your component styles here */
.query-row {
    height: 50px;
}
.table-container {
    height: calc(100% - 50px);
}
.el-tag +.el-tag {
    margin-left: 10px;
}
</style>
