<template>
    <fieldset>
        <legend>
            工作时间
        </legend>
        <el-table
            ref="elTable"
            class="days"
            :data="data"
            :border="true">
            <el-table-column prop="index" align="center" label="" width="100">
                <template #default="scope">
                    <p>第{{ scope.$index + 1 }}天 </p>
                </template>
            </el-table-column>
            <el-table-column label="起始时间" prop="start_time" header-align="center" align="center" />
            <el-table-column label="截至时间" prop="end_time" header-align="center" align="center" />
        </el-table>
    </fieldset>
</template>

<script>
export default {
    components: {
    },
    props: {
        data: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
    },
};
</script>
<style scoped>
fieldset {
    border-color: darkgray;
}
</style>
