<template>
    <div class="main_div">
        <el-form
            :model="args"
            class="search_form">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="名称" prop="name">
                        <el-input
                            v-model="args.name"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="6">
                    <el-form-item label="开始日期起" class="label_style" prop="date_start">
                        <el-date-picker v-model="args.date_start" type="date" />
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="6">
                    <el-form-item label="开始日期止" class="label_style" prop="date_end">
                        <el-date-picker v-model="args.date_end" type="date" />
                    </el-form-item>
                </el-col>
                <el-col :span="2" />
                <el-col :span="2">
                    <el-button
                        type="primary"
                        @click="init_data">
                        <el-icon><Search /></el-icon>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table
            ref="elTable"
            class="calendar_list"
            :data="calendar_list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id"
            :expand-row-keys="expand_row_keys"
            @expand-change="on_expand_change">
            <el-table-column fixed type="expand">
                <template #default="props">
                    <div>
                        <tabCalendarTime :data="props.row.days" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed type="index" align="center" />
            <el-table-column
                v-for="(col, i) in tableCols"
                :key="i"
                :label="col.label"
                v-bind="col"
                header-align="center"
                :align="col.align" />
            <el-table-column fixed="right" label="" width="100" align="center">
                <template #header>
                    <el-button
                        class="btn-add"
                        @click="add_calendar()">
                        <el-icon><plus /></el-icon>
                    </el-button>
                </template>
                <template #default="data">
                    <el-tooltip content="编辑日历" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            @click="edit_calendar($event, data.row)">
                            <el-icon><edit /></el-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="删除日历" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            @click="del_calendar($event, data.row)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            align="center"
            class="page"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="calendar_list.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
    </div>
    <calendarOpt ref="calendarOpt" />
</template>
<script>
import {notify} from '../assets/js/utils.js';
import {Search, Edit, Plus, Delete} from '@element-plus/icons-vue';
import calendarOpt from './calendar-opt.vue';
import tabCalendarTime from './tab-calendar-time.vue';
export default {
    name: 'CalendarList',
    components: {
        Search,
        Edit,
        Plus,
        Delete,
        calendarOpt,
        tabCalendarTime,
    },
    data: function() {
        return {
            args: {
                name: '',
                date_start: '',
                date_end: '',
            },
            expand_row_keys: [],
            calendar_list: [],
            category_list: [],
            mac_data: {},
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 10, // 每页的数据条数
        };
    },
    computed: {
        tableCols() {
            return [
                {
                    prop: 'name',
                    label: '名称',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'start',
                    label: '开始日期',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'dcnt',
                    label: '规则周期',
                    width: 150,
                    align: 'center',
                },
                {prop: 'repeat_name', label: '规则模式', align: 'center', width: 120},
                {prop: 'machine_name', label: '机台', align: 'left'},
            ];
        },
    },
    mounted() {
        this.init_data();
        this.initCategory();
    },
    methods: {
        on_expand_change(row, children) {
            const key = row.id;
            const index = this.expand_row_keys.indexOf(key);
            if (index < 0) {
                this.expand_row_keys.push(key);
            } else {
                this.expand_row_keys.splice(index, 1);
            }
        },
        async add_calendar() {
            const data = await this.$refs.calendarOpt.show({
                title: '日历添加',
                data: null,
                calendar_list: this.calendar_list,
            });
            if (data) {
                this.init_data();
            }
        },
        async del_calendar(_, row) {
            this.$confirm('是否确认删除该日历规则', '删除提示', {
                iconClass: 'el-icon-question', // 自定义图标样式
                confirmButtonText: '确认', // 确认按钮文字更换
                cancelButtonText: '取消', // 取消按钮文字更换
                showClose: true, // 是否显示右上角关闭按钮
                type: 'warning', // 提示类型  success/info/warning/error
            }).then(async () => {
                let success = true;
                let message = '提交成功';
                for (let i = 0; i < row.id.length; i++) {
                    const url = `/api/calendar/${row.id[i]}`;
                    const res = await this.axios.delete(url);
                    if (res.data.error) {
                        success = false;
                        message = res.data.error;
                        break;
                    }
                }
                if (success) {
                    notify('success', '删除成功', 0);
                } else {
                    notify('error', message, 0);
                }
                this.init_data();
            }).catch(() => {
            });
        },
        async edit_calendar(_, row) {
            const data = await this.$refs.calendarOpt.show({
                title: '日历修改',
                data: JSON.parse(JSON.stringify(row)),
                calendar_list: this.calendar_list,
            });
            if (data) {
                this.init_data();
            }
        },
        async init_data() {
            const url = `/api/calendar/`;
            const args = [];
            if (this.args.name) {
                args.push(`q=${this.args.name}`);
            }
            if (this.args.date_start) {
                args.push(`dts=${this.date_format(this.args.date_start)}`);
            }
            if (this.args.date_end) {
                args.push(`dte=${this.date_format(this.args.date_end)}`);
            }
            const args_url = args.length > 0 && '?' + args.join('&&') || '';
            const res = await this.axios.get(url + args_url);
            const calendar_list = res.data.data;
            for (let i = 0; i < calendar_list.length; i++) {
                calendar_list[i]['repeat_name'] = calendar_list[i]['repeat'] > 0 && '循环' || '临时';
                const url = `/api/machines/${calendar_list[i]['mach_id']}`;
                const mac_res = await this.axios.get(url);
                calendar_list[i]['machine'] = mac_res.data.data;
                calendar_list[i]['machine_name'] = mac_res.data.data['name'];
                calendar_list[i]['days'] = calendar_list[i]['days'].reduce((res, data) => {
                    if (data?.length > 0) {
                        res.push({
                            'start_time': data[0],
                            'end_time': data[1],
                        });
                    } else {
                        res.push({
                            'start_time': null,
                            'end_time': null,
                        });
                    }
                    return res;
                }, []);
            }
            this.calendar_list = Object.values(calendar_list.reduce((result, data) => {
                if (data['state'] > 0) {
                    const key = `${data['name']}/${data['start']}/${data['repeat']}`;
                    const id = data['id'];
                    const machine = data['machine'];
                    const machine_name = data['machine_name'];
                    machine['calender_id'] = id;
                    if (!result[key]) {
                        result[key] = data;
                        result[key]['id'] = [id];
                        result[key]['machine'] = [machine];
                        result[key]['machine_names'] = [machine_name];
                    } else {
                        result[key]['id'].push(id);
                        result[key]['machine'].push(machine);
                        result[key]['machine_names'].push(machine_name);
                    }
                    result[key]['machine_name'] = result[key]['machine_names'].join(',');
                }
                return result;
            }, {}));
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        async initCategory() {
            const url = `/api/categories/`;
            const res = await this.axios.get(url);
            this.category_list = res.data.data;
        },
        formatParams(json) {
            const param_list = [];
            Object.keys(json).forEach(function(key) {
                if (Array.isArray(json[key])) {
                    param_list.push(`${key}:${json[key].join('~')}`);
                } else {
                    param_list.push(`${key}:${json[key]}`);
                }
            });
            return param_list;
        },
        date_format(date) {
            const diff_hour = date.getTimezoneOffset() / 60;
            const new_date = date.setHours(date.getHours() - diff_hour);
            return new Date(new_date).toISOString().slice(0, 10);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_div{
    position: relative;
    width: 100%;
    height: 100%;
}
.calendar_list{
    position: absolute;
    top:50px;
    bottom: 60px;
    height: calc(100% - 110px);
}
.search_form {
    height:50px;
}
.page{
    position: absolute;
    height: 50px;
    bottom: 10px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
</style>
