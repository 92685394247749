<template>
    <div class="main_div">
        <el-form
            :model="args"
            class="search_form">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="企业" class="label_style" prop="corp">
                        <el-select
                            ref="corpSelect"
                            v-model="args.corp"
                            autocomplete="off"
                            placeholder="请选择"
                            @change="init_data">
                            <el-option
                                v-for="(corp_data, i) in corp_list"
                                :key="i"
                                :label="corp_data.name"
                                :value="corp_data.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="6">
                    <el-form-item label="账号" prop="username">
                        <el-input
                            v-model="args.username"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="9" />
                <el-col :span="2">
                    <el-button
                        type="primary"
                        @click="init_data">
                        <el-icon><Search /></el-icon>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table
            ref="elTable"
            class="user_list"
            :data="user_list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id"
            :expand-row-keys="expand_row_keys"
            @expand-change="on_expand_change">
            <el-table-column fixed type="index" align="center" />
            <el-table-column
                v-for="(col, i) in tableCols"
                :key="i"
                :label="col.label"
                v-bind="col"
                header-align="center"
                :align="col.align" />
            <el-table-column fixed="right" label="" width="100" align="center">
                <template #header>
                    <el-tooltip v-if="args?.corp" content="添加账号" placement="top">
                        <el-button
                            class="btn-add"
                            @click="add_user()">
                            <el-icon><plus /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
                <template #default="data">
                    <el-tooltip content="编辑账号" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            @click="edit_user($event, data.row)">
                            <el-icon><edit /></el-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="删除账号" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click="del_user($event, data.row)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            align="center"
            class="page"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="user_list.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
    </div>
    <userOpt ref="userOpt" />
</template>
<script>
import {notify} from '../assets/js/utils.js';
import {Search, Edit, Plus, Delete} from '@element-plus/icons-vue';
import userOpt from './user-opt.vue';
export default {
    name: 'UserList',
    components: {
        Search,
        Edit,
        Plus,
        Delete,
        userOpt,
    },
    data: function() {
        return {
            args: {
                machine: '',
                category: '',
            },
            expand_row_keys: [],
            user_list: [],
            corp_list: [],
            category_list: [],
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 10, // 每页的数据条数
        };
    },
    computed: {
        tableCols() {
            return [
                {prop: 'username', label: '账号', width: 200, align: 'center'},
                {prop: 'email', label: '邮箱', width: 250, align: 'center'},
                {
                    prop: 'role_names',
                    label: '角色',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'state',
                    label: '状态',
                    width: 150,
                    align: 'center',
                },
                {prop: 'create_datetime', label: '创建时间', align: 'center'},
            ];
        },
        cardItems() {
            return [
                {prop: 'code', label: '编码', width: 80},
                {prop: 'name', label: '名称', width: 80},
                {prop: 'cate', label: '类型', width: 80},
                {prop: 'speed', label: '最大机速', width: 120},
            ];
        },
    },
    mounted() {
        this.initCorp();
    },
    methods: {
        on_expand_change(row, children) {
            const key = row.id;
            const index = this.expand_row_keys.indexOf(key);
            if (index < 0) {
                this.expand_row_keys.push(key);
            } else {
                this.expand_row_keys.splice(index, 1);
            }
        },
        async add_user() {
            const data = await this.$refs.userOpt.show({
                title: '账号添加',
                data: {
                    corp_id: this.args.corp,
                    corp_name: this.corp_list.reduce((res, data) => {
                        if (data.id === this.args.corp) {
                            res = data;
                        }
                        return res;
                    }).name,
                    corp_code: this.corp_list.reduce((res, data) => {
                        if (data.id === this.args.corp) {
                            res = data;
                        }
                        return res;
                    }).code,
                    roles: [],
                },
                user_list: this.user_list,
            });
            if (data) {
                this.init_data();
            }
        },
        async edit_user(_, row) {
            const data = await this.$refs.userOpt.show({
                title: '账号修改',
                data: JSON.parse(JSON.stringify(row)),
                user_list: this.user_list,
            });
            if (data) {
                this.init_data();
            }
        },
        async del_user(_, row) {
            this.$confirm('是否确认删除该账号', '删除提示', {
                iconClass: 'el-icon-question', // 自定义图标样式
                confirmButtonText: '确认', // 确认按钮文字更换
                cancelButtonText: '取消', // 取消按钮文字更换
                showClose: true, // 是否显示右上角关闭按钮
                type: 'warning', // 提示类型  success/info/warning/error
            }).then(async () => {
                const url = `/api/users/${row.id}`;
                const res = await this.axios.delete(url);
                if (res.data.result) {
                    notify('success', '删除成功', 0);
                } else {
                    notify('error', res.data.error, 0);
                }
                this.init_data();
            }).catch(() => {
            });
        },
        async init_data() {
            if (!this.args.corp) {
                notify('error', '请先选择企业！', 0);
                this.$refs.corpSelect.focus();
                return;
            }
            const url = `/api/users/?c=${this.args.corp}`;
            const args = [];
            if (this.args.username) {
                args.push(`q=${this.args.username}`);
            }
            const args_url = args.length > 0 && '?' + args.join('&&') || '';
            const res = await this.axios.get(url + args_url);
            this.user_list = res.data.data;
            for (let i = 0; i < this.user_list.length; i++) {
                const role_names = this.user_list[i].roles?.reduce((res, data) => {
                    res.push(data.name);
                    return res;
                }, []) || [];
                this.user_list[i]['role_names'] = role_names.join(',');
                this.user_list[i]['create_datetime'] = this.user_list[i]['create_datetime'].replace('T', ' ');
                this.user_list[i]['state'] = this.user_list[i]['active'] && '活跃' || '冻结';
                this.user_list[i]['corp_name'] = this.corp_list.reduce((res, data) => {
                    if (data.id === this.args.corp) {
                        res = data;
                    }
                    return res;
                }).name;
                this.user_list[i]['corp_code'] = this.corp_list.reduce((res, data) => {
                    if (data.id === this.args.corp) {
                        res = data;
                    }
                    return res;
                }).code;
            }
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        async initCorp() {
            const url = `/api/corporations/`;
            const res = await this.axios.get(url);
            this.corp_list = res.data.data;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_div{
    position: relative;
    width: 100%;
    height: 100%;
}
.user_list{
    position: absolute;
    top:50px;
    bottom: 60px;
    height: calc(100% - 110px);
}
.search_form {
    height:50px;
}
.page{
    position: absolute;
    height: 50px;
    bottom: 10px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
</style>
