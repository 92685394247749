/* eslint no-eval: off */
import {defineStore} from 'pinia';
import axios from 'axios';
import {notify} from '../assets/js/utils.js';
import {json_parse} from '../assets/js/json.js';
import {UserStore} from './user.js';

async function asleep(ms) {
    await new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

const ComputingStore = defineStore('computing', {
    state: () => ({
        url_api: `${process.env?.VUE_APP_API_URL}/api/c`,
        url_sse: `${process.env?.VUE_APP_API_URL}/stream/`,
        data: {},
    }),
    getters: {
        source(state) {
            if (!state._source) {
                state._source = new EventSource(this.url_sse);
            }
            return state._source;
        },
    },
    actions: {
        get_watch_type(category) {
            const corp_id = UserStore().user.corp_id;
            return `${corp_id}/${category}`;
        },

        async try_get(url, params) {
            try {
                // GET请求
                const res = await axios.get(url, {
                    params,
                    transformResponse(data) {
                        return json_parse(data);
                    },
                });
                (res.messages || []).forEach(async msg => {
                    // 延迟测量避免notify显示重叠，可能是notify显示的计算的一种缺陷
                    await asleep(1);
                    // 显示警告信息
                    notify('warning', msg, 0);
                });
                return res;
            } catch (exc) {
                if (exc.response?.status !== 401) {
                    console.error(exc);
                }
            }
            return false;
        },

        append_log(category, log) {
            const catedata = this.data[category];
            console.log(log);
            catedata.logs.push(...log.logs);
            if (log.state !== null && log.state !== null && !(catedata <= 0)) {
                catedata.state = log.state;
            }
            const keys = ['uuid', 'type', 'start_at', 'end_time', 'time_limit', 'user'];
            keys.forEach(key => {
                if (log[key]) catedata[key] = log[key];
            });
        },

        handle_log(event) {
            const res_data = JSON.parse(event.data);
            this.append_log(res_data.category, res_data);
            console.log(res_data);
        },

        async watch(type) {
            if (this.data[type]) {
                const eventtype = this.get_watch_type(type);
                console.warn(`${eventtype} is already on watching.`);
                return;
            }

            // 创建数据
            this.data[type] = {
                type,
                state: null,
                logs: [],
            };

            // 创建 EventSource 对象连接服务器
            const source = this.source;
            // 服务器发送信息到客户端时，会触发
            const eventtype = this.get_watch_type(type);
            source.addEventListener(eventtype, this.handle_log, false);
            console.log('watching', eventtype);
            // 连接异常时会触发 error 事件并自动重连
            source.addEventListener(
                'error',
                function(event) {
                    if (event.target.readyState === EventSource.CLOSED) {
                        console.log('Disconnected');
                    } else if (event.target.readyState === EventSource.CONNECTING) {
                        console.log('Connecting...');
                    }
                },
                false,
            );

            // 请求最后状态
            const url = `${this.url_api}/${type}/status`;
            const rs = await this.try_get(url);
            if (rs.status === 200) {
                this.append_log(type, rs.data);
            } else {
                console.error(rs);
                notify('warn', rs.statusText);
            }
        },
    },
});

export default ComputingStore;
export {ComputingStore};
