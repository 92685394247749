<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="80%"
        height="70%"
        align-center
        class="speeds-editor"
        @keyup.enter="doConfirm">
        <el-table
            ref="elTable"
            class="speeds"
            height="500px"
            :data="speeds"
            :border="true">
            <el-table-column fixed type="index" align="center" width="100" />
            <el-table-column
                v-for="(col, i) in tableCols"
                :key="i"
                :label="col.label"
                v-bind="col"
                align="center">
                <template #default="props">
                    <input-number-range
                        v-if="col.mode=='range'"
                        :row-index="props.$index"
                        :param-key="col.label"
                        :value="props.row[col.label]"
                        :precision="0"
                        @input="input" />
                    <el-select
                        v-if="col.mode=='contains'"
                        v-model="props.row[col.label]"
                        multiple
                        autocomplete="off"
                        :placeholder="col.detail">
                        <el-option
                            v-for="(v, k) in col.source"
                            :key="k"
                            :label="v"
                            :value="v" />
                    </el-select>
                    <el-select
                        v-if="col.mode=='equal'"
                        v-model="props.row[col.label]"
                        autocomplete="off"
                        :placeholder="col.detail">
                        <el-option
                            v-for="(v, k) in col.source"
                            :key="k"
                            :label="v"
                            :value="v" />
                    </el-select>
                    <el-input
                        v-if="col.mode=='input'"
                        v-model="props.row[col.label]"
                        autocomplete="off"
                        :placeholder="col.detail"
                        clearable />
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="" width="100" align="center">
                <template #header>
                    <el-button
                        class="btn-add"
                        @click="add_speeds()">
                        <el-icon><plus /></el-icon>
                    </el-button>
                </template>
                <template #default="props">
                    <el-tooltip content="删除" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            @click="del_speeds($event, props.$index)">
                            <el-icon><delete /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <div class="foot_div">
            <el-row>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        @click="visibled = false">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        :disabled="!validate"
                        type="primary"
                        @click="doConfirm">
                        保存
                    </el-button>
                </el-col>
                <el-col :span="4" />
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
import {notify} from '../assets/js/utils.js';
import {Plus, Delete} from '@element-plus/icons-vue';
import inputNumberRange from './inputNumberRange.vue';
export default {
    name: 'SpeedsOpt',
    components: {
        Delete,
        Plus,
        inputNumberRange,
    },
    props: {
    },
    data() {
        return {
            height: document.body.clientWidth,
            data: {},
            visibled: false,
            resolve: null,
            reject: null,
            title: '',
            speeds: [],
            tableCols: [],
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    mounted() {
    },
    methods: {
        async show(options) {
            this.visibled = true;
            this.title = options.title;
            this.data = options.data;
            this.speeds = options.data.speeds.reduce((result, data) => {
                result.push(Object.assign({}, {'机速(%)': data['value']}, data['props']));
                return result;
            }, []);
            const tableCols = [];
            tableCols.push({
                label: '机速(%)',
                mode: 'input',
                type: 'integer',
                detail: '请输入机器最大速度的百分比',
            });
            for (const key in this.data.data) {
                tableCols.push({
                    label: key,
                    mode: this.data.data[key].mode,
                    type: this.data.data[key].type,
                    source: this.data.data[key].source,
                    detail: this.data.data[key].detail,
                });
            }
            this.tableCols = tableCols;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm() {
            try {
                if (!(await this.checked())) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            let data = {};
            for (let i = 0; i < this.data.speeds.length; i++) {
                const url = `/api/speeds/${this.data.speeds[i].id}`;
                await this.axios.delete(url);
            }
            let success = true;
            let message = '提交失败';
            const speeds = JSON.parse(JSON.stringify(this.speeds));
            for (let i = 0; i < speeds.length; i++) {
                const url = `/api/speeds/`;
                const speed_value = speeds[i]['机速(%)'];
                delete speeds[i]['机速(%)'];
                data = {
                    mode_id: this.data.id,
                    value: speed_value,
                    props: speeds[i],
                };
                const res = await this.axios.post(url, JSON.stringify(data));
                if (res.data.error) {
                    success = false;
                    message = res.data.error;
                    break;
                }
            }
            if (!success) {
                notify('error', message, 0);
                return;
            }
            this.resolve(data);
            // event confirm
            // this.$emit('confirm', data);
            // disable reject
            this.reject = null;
            // close dialog
            this.visibled = false;
        },
        async checked() {
            let flag = true;
            if (this.speeds.length === 0) {
                notify('error', '请设置机速', 0);
                flag = false;
                return flag;
            }
            if (this.speeds.length > 0) {
                for (let i = 0; i < this.speeds.length; i++) {
                    for (const key in this.speeds[i]) {
                        if (key === '机速(%)') {
                            if (isNaN(this.speeds[i][key]) || this.speeds[i][key] <= 0) {
                                notify('error', `第${i}行，机速必须是大于0的数字！`, 0);
                                flag = false;
                                break;
                            }
                        }
                        if (!this.speeds[i][key]) {
                            notify('error', `第${i}行，"${key}"不能为空!`, 0);
                            flag = false;
                            break;
                        }
                    }
                    if (!flag) {
                        break;
                    }
                }
                if (!flag) {
                    return flag;
                }
            }
            return flag;
        },
        add_speeds() {
            const speed_data = {};
            for (let i = 0; i < this.tableCols.length; i++) {
                speed_data[this.tableCols[i]['label']] = '';
            }
            this.speeds.push(speed_data);
        },
        del_speeds(_, index) {
            this.speeds.splice(index, 1);
        },
        input(event, k, v, index) {
            console.log(k, v);
            if (index >= 0) {
                this.speeds[index][k] = v;
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.speeds-editor{
    height: 100% !important;
    max-height: 800px;
    max-width: 1280px;
}
</style>
