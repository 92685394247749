<template>
    <el-switch
        v-model="sValue"
        inline-prompt
        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
        active-text="是"
        inactive-text="否"
        :disabled="disabled"
        @change="switchValue" />
</template>

<script setup>
import {ref, watch, defineProps, defineEmits} from 'vue';

const props = defineProps({
    state: {
        type: Boolean,
        default: true,
    },
    serialNumber: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['switch']);

const sValue = ref(props.state);

watch(() => props.state, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        sValue.value = newVal;
    }
});

const switchValue = () => {
    emit('switch', {
        value: sValue.value,
        serial_number: props.serialNumber,
        key: 'isIncludedInCalculation',
    });
};

</script>
