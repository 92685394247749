<!-- 自定义switch -->
<template>
    <el-switch
        v-model="sValue"
        inline-prompt
        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
        active-value="否"
        active-text="否"
        inactive-text="是"
        inactive-value="是"
        @change="switchValue" />
</template>
<script setup>
import {ref, watch, defineProps, defineEmits} from 'vue';

const props = defineProps({
    state: {
        type: String,
        default: '',
    },
    serialNumber: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['switch']);

const sValue = ref(props.state);

watch(() => props.state, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        sValue.value = newVal;
    }
});

const switchValue = () => {
    emit('switch', {
        value: sValue.value,
        serial_number: props.serialNumber,
        key: 'is_finished',
    });
};
</script>
