/* eslint no-eval: off */
/**
 * 计算日志模块
 */
import {defineStore} from 'pinia';
import {json_parse} from '../assets/js/json.js';
import {getCurrentInstance} from 'vue';
import axios from 'axios';
import {notify} from '../assets/js/utils.js';
// import qs from 'qs';
// import {useSSE} from 'vue-sse';
// import VueSSE from 'vue-sse';
// import {useEventSource} from 'vue-sse';
// import VueSSE from 'vue-sse';

async function asleep(ms) {
    await new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

class Logger {
    constructor(options) {
        this._options = options;
        this._connected = false;
    }

    get type() {
        return this._options.type;
    }

    get sse() {
        return this._options.sse;
        // const vueapp = getCurrentInstance();
        // return vueapp.proxy.$sse;
    }

    get cache() {
        return this._options.cache;
    }

    get state() {
        return this._client.source.readyState;
    }

    _create() {
        console.log(`create ${this.type}`);
        this._client = this.sse.create()
            .on('event', msg => {
                if (typeof this.onevent === 'function') {
                    this.onevent(msg);
                };
                console.log('event', msg);
            })
            .on(this.type, msg => {
                // msg = {
                //     "children": [],
                //     "date_done": "2024-04-16T11:02:24.400281",
                //     "result": "maximum recursion depth exceeded while calling a Python object",
                //     "status": "FAILURE",
                //     "task_id": "1338397f-4107-4064-9e95-3aecaa4be6ed",
                //     "traceback":
                // }
                try {
                    msg = json_parse(msg);
                    if (!('message' in msg)) {
                        if (msg.result && typeof msg.result === 'object') {
                            msg = Object.assign({}, msg.result, {status: msg.status});
                        }
                    }
                    console.log(this.type, msg);
                    this.cache.push(msg);
                    switch (msg.status) {
                    case 'SUCCESS':
                        if (this.onsuccess) {
                            this.onsuccess(msg);
                        }
                        break;
                    case 'FAILURE':
                        if (this.onfailure) {
                            this.onfailure(msg);
                        }
                    }
                } catch (exc) {
                    console.error(exc);
                }
            })
            .on('error', err => {
                if (err === undefined) {
                    const state = this.state;
                    if (state === 2) {
                        this._connected = false;
                        const msg = `Connection losted. state: ${state}`;
                        console.error(msg);
                        this.reconnect();
                    } else {
                        const msg = `Connection error. state: ${state} `;
                        console.error(msg);
                        this.reconnect();
                    }
                } else {
                    const msg = 'Failed to parse.';
                    console.error(msg, err);
                    this.cache.push({message: `{msg} \n`, error: err});
                }
            });
        return this;
    }

    connect(silent) {
        if (this._connected) {
            if (!silent) throw Error('Already connected.');
            return this;
        }
        this._client.connect().catch(err => {
            const msg = 'Failed make initial connection:';
            console.error(msg, err);
            this.cache.push({message: msg, error: err});
        });
        this._client.source.onerror = (err) => {
            console.error(err);
        };
        this._connected = true;
        return this;
    }

    disconnect() {
        if (this._client) {
            this._client.disconnect();
            console.log('disconnect', this._client);
            this.cache.push({message: 'disconnect'});
        }
        this._connected = false;
    }

    reconnect() {
        this.disconnect();
        this.connect();
    }
}

const LoggerStore = defineStore('logger', {
    state: () => ({
        cache: {},
        clients: {},
        messages: [],
    }),
    getters: {
        logs(state) {
            return state.cache;
        },
        sse() {
            const vueapp = getCurrentInstance();
            return vueapp.proxy.$sse;
        },
    },
    actions: {
        create(type) {
            if (!(type in this.clients)) {
                const sse = this.sse;
                if (!(type in this.cache)) {
                    this.cache[type] = [];
                }
                const cache = this.cache[type];
                const client = new Logger({type, sse, cache})._create();
                this.clients[type] = client;
            }
            return this.clients[type];
        },

        async try_load(url, params) {
            try {
                const res = await axios.get(url, {
                    params,
                    transformResponse(data) {
                        return json_parse(data);
                    },
                });
                // console.debug('loaded content type', res.headers['content-type']);
                // console.debug('loaded:', res);
                (res.messages || []).forEach(async msg => {
                    // 延迟测量避免notify显示重叠，可能是notify显示的计算的一种缺陷
                    await asleep(1);
                    // 显示警告信息
                    notify('warning', msg, 0);
                });
                return res;
            } catch (exc) {
                if (exc.response?.status !== 401) {
                    console.error(exc);
                }
            }
        },

    },
});

export default LoggerStore;
export {LoggerStore};
