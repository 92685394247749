import axios from 'axios';

// 任务单拆分
/****************************
 * data: {
 *          name:<string>产品名称,
 *          quantity:<integer>任务数量,
 *          plan_date:<date>计划开始日期,
 *          delivery_date：<date>交货日期
 * }
 **/
async function task_split(data) {
    const url = `/api/products/?q=${data.name}&withspeeds=true`;
    const res = await axios.get(url);
    console.log('task_split', res, data);
    if (res.data.data?.length > 0) {
        const process_list = res.data.data[0].data['工艺'];
        const last_process_list = process_list.reduce((res, data) => {
            res.push(...(data['last_process'] || []));
            return res;
        }, []);
        let tail_process = '';
        for (let i = 0; i < process_list.length; i++) {
            if (last_process_list.indexOf(process_list[i]['name']) === -1) {
                tail_process = process_list[i]['name'];
            }
        }
        const process_data = process_list.reduce((res, data) => {
            res[data['name']] = data;
            return res;
        }, {});
        const latest_date = new Date(data['delivery_date']);
        const delivery_days = 3;
        latest_date.setDate(latest_date.getDate() - delivery_days);
        const res_list = await get_process_info([], tail_process, data.quantity, process_data, latest_date);
        get_earlist_started(res_list, data['plan_date'], null);
        console.log(res_list);
        return res_list;
    } else {
        return {
            state: -1,
            message: `产品"${data.name}"还没有配置生产档案，无法拆分，请先配置！`,
        };
    }
}

// 递推获取每道工序的任务量和生产速度
async function get_process_info(res, name, quantity, process_data, latest_date) {
    const speed_hour = await get_speedAndstandardHour(process_data[name]);
    const speed = speed_hour.speed;
    let produce_time = quantity / speed / 60;
    const standard_work_hour = speed_hour.standard_hour; // 标准工作时间
    const last_process_quantity = {};
    for (let i = 0; i < (process_data[name].last_process || []).length; i++) {
        const last_name = process_data[name].last_process[i];
        const last_output_ratio = process_data[last_name].output_ratio || 1;
        const last_quantity = quantity / process_data[name].output_ratio * last_output_ratio;
        const last_produce_time = last_quantity / speed / 60;
        if (produce_time > 0) {
            produce_time = last_produce_time > produce_time && produce_time || last_produce_time;
        } else {
            produce_time = last_produce_time;
        }
        last_process_quantity[last_name] = last_quantity;
    }
    const work_days = Math.ceil(produce_time / standard_work_hour);
    latest_date.setDate(latest_date.getDate() - work_days);
    res.push(Object.assign(process_data[name], {
        produce_quantity: quantity,
        time: produce_time,
        latest_finished: new Date(latest_date),
    }));
    for (let i = 0; i < (process_data[name].last_process || []).length; i++) {
        const last_name = process_data[name].last_process[i];
        await get_process_info(
            res, last_name,
            last_process_quantity[last_name], process_data, new Date(latest_date));
    }
    return res;
}

// 计算最早开工日期
function get_earlist_started(res, plan_date, last_process) {
    if (last_process) {
        for (let i = 0; i < res.length; i++) {
            if ((res[i].last_process || []).indexOf(last_process['name']) >= 0) {
                let earlist_date = new Date(plan_date);
                if (res[i]['earlist_date']) {
                    if (res[i]['earlist_date'].getTime() < earlist_date.getTime()) {
                        earlist_date = new Date(res[i]['earlist_date']);
                    }
                } else {
                    res[i]['earlist_date'] = new Date(earlist_date);
                }
                const speed = 100;
                const produce_time = res[i]['produce_quantity'] / speed / 60;
                const standard_work_hour = 10; // 标准工作时间
                const work_days = Math.ceil(produce_time / standard_work_hour);
                earlist_date.setDate(earlist_date.getDate() + work_days);
                get_earlist_started(res, earlist_date, res[i]);
            }
        }
    } else {
        for (let i = 0; i < res.length; i++) {
            if ((res[i].last_process || []).length === 0) {
                const earlist_date = new Date(plan_date);
                res[i]['earlist_date'] = new Date(earlist_date);
                const speed = 100;
                const produce_time = res[i]['produce_quantity'] / speed / 60;
                const standard_work_hour = 10; // 标准工作时间
                const work_days = Math.ceil(produce_time / standard_work_hour);
                earlist_date.setDate(earlist_date.getDate() + work_days);
                get_earlist_started(res, earlist_date, res[i]);
            }
        }
    }
}

// 获取生产速度(多个速度取最小值)和每日标准工时
async function get_speedAndstandardHour(data) {
    const speeds = data.speeds;
    let min_speed = null;
    for (const key in speeds) {
        if (min_speed) {
            if (min_speed.speed > speeds[key]) {
                min_speed['machine'] = key;
                min_speed['speed'] = speeds[key];
            }
        } else {
            min_speed = {
                machine: key,
                speed: speeds[key],
            };
        }
    }
    // 获取默认工时
    // 获取机台工作日历
    const url1 = `/api/parameters/?c=${data['cate_code']}&q=工时`;
    const res1 = (await axios.get(url1)).data.data;
    let hour = res1.length > 0 && (res1.data || res1.defined.args.default) || 10;
    const url = `/api/calendar/?mid=${min_speed?.machine}`;
    const res = await axios.get(url);
    if (res.data.data.length > 0) {
        const calendar = res.data.data;
        for (let i = 0; i < calendar.length; i++) {
            if (calendar[i].repeat === 1) {
                if (calendar[i].days.length > 0) {
                    const start_time = calendar[i].days[0][0];
                    const startDate = new Date();
                    startDate.setHours(start_time.split(':')[0], start_time.split(':')[1]);
                    const end_time = calendar[i].days[0][1];
                    const endDate = new Date();
                    endDate.setHours(end_time.split(':')[0], end_time.split(':')[1]);
                    hour = (Math.abs(endDate - startDate) / 3600 / 1000) || 24;
                }
            }
        }
    }
    return {
        speed: min_speed?.speed || 100,
        standard_hour: hour,
    };
}
export default task_split;
export {task_split};
