<template>
    <div class="App common-layout">
        <el-container class="main-container">
            <el-header class="header">
                <el-container class="header-container">
                    <el-aside class="header-item">
                        <label class="header-label">{{ caption }}</label>
                    </el-aside>
                    <el-main class="header-item">
                        <el-dropdown
                            v-if="userstore?.is_logged && admin_routes.length"
                            class="usermenu"
                            @command="handleCommand">
                            <span class="el-dropdown-link">
                                <el-icon><Setting /></el-icon>
                                管理员
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <template v-for="(route, index) in admin_routes" :key="index">
                                        <el-dropdown-item :command="`route:${route.path}`">
                                            {{ route.label }}
                                        </el-dropdown-item>
                                    </template>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        &nbsp;
                        <el-dropdown
                            v-if="userstore?.is_logged"
                            class="usermenu"
                            @command="handleCommand">
                            <span class="el-dropdown-link">
                                <el-icon><Avatar /></el-icon>
                                {{ userstore?.user?.username ?? '未登录' }}
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item command="account"> 帐号 </el-dropdown-item>
                                    <template v-for="(route, index) in user_routes" :key="index">
                                        <el-dropdown-item
                                            :divided="!index"
                                            :command="`route:${route.path}`">
                                            {{ route.label }}
                                        </el-dropdown-item>
                                    </template>
                                    <el-dropdown-item divided command="logout">
                                        注销
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </el-main>
                </el-container>
            </el-header>
            <el-main>
                <router-view />
            </el-main>
        </el-container>
    </div>
</template>

<script>
import {Avatar, Setting} from '@element-plus/icons-vue';
import UserStore from './stores/user.js';
import {routes, get_menu_routes, get_admin_routes, get_user_routes} from './router.js';

export default {
    name: 'App',
    components: {Avatar, Setting},
    setup() {
        const userstore = UserStore();
        return {userstore};
    },
    data() {
        return {};
    },
    computed: {
        user_routes() {
            return get_user_routes();
        },
        admin_routes() {
            return get_admin_routes();
        },
        routes() {
            return get_menu_routes();
        },
        router_names() {
            return routes.reduce((r, x) => {
                r[x.name] = x.label;
                return r;
            }, {});
        },
        corporation() {
            return this.userstore?.user?.corporation;
        },
        caption() {
            const current_route_name = this.$router.currentRoute.value?.name;
            const rmap = this.router_names;
            const pagename = rmap[current_route_name];
            const admin_routes = get_admin_routes();
            const toplabel = admin_routes.some(x => x.name === current_route_name)
                ? '系统管理'
                : this.userstore?.user?.corporation?.name;

            return toplabel ? `${toplabel}::${pagename}` : '';
        },
    },
    methods: {
        handleCommand(command) {
            if (command.path || command.indexOf(':') > 0) {
                const path = command.path ?? command.substr(command.indexOf(':') + 1);
                this.app = command;
                this.$router.push(path);
            } else {
                switch (command) {
                case 'logout':
                    (async () => {
                        await this.userstore.logout();
                        this.$router.push('login');
                    })();
                    break;
                }
            }
        },
    },
};
</script>

<style>
html,
body,
#app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
.view {
    margin-top: 60px;
}
</style>
<style scoped>
.App {
    width: 100%;
    height: 100%;
}
.main-container {
    height: 100%;
    top: 0;
    bottom: 0;
}
.header {
    height: 40px;
}
.header-item {
    padding-top: 10px;
    padding-bottom: 10px;
}
.header-item:first-child {
    text-align: left;
}
.header-item:last-child {
    text-align: right;
    padding-right: 0;
}
.header-label {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
}
.usermenu {
    top: 50%;
    height: 20px;
    margin-top: -10px;
}

.example-showcase .el-dropdown + .el-dropdown {
    margin-left: 15px;
}
.el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
    line-height: normal;
}
</style>
