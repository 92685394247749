/* eslint no-eval: off */
import {defineStore} from 'pinia';
import axios from 'axios';
import {notify} from '../assets/js/utils.js';
import {afterLogin, afterLogout} from '../assets/js/localStorage.js';
import SchdStore from './schdtabular.js';
import {json_parse, stringify} from '../assets/js/json.js';
// import qs from 'qs';

async function asleep(ms) {
    await new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

const UserStore = defineStore('userstore', {
    state: () => ({
        data: null,
    }),
    getters: {
        user(state) {
            return state.data;
        },
        is_admin(state) {
            return state.has_role('admin');
        },
        is_logged(state) {
            return !!state.data;
        },
    },
    actions: {
        has_role(rolename) {
            return this.data?.roles.some(x => x.name === rolename);
        },

        async try_load(url, params) {
            try {
                const res = await axios.get(url, {
                    params,
                    transformResponse(data) {
                        return json_parse(data);
                    },
                });
                // console.debug('loaded content type', res.headers['content-type']);
                // console.debug('loaded:', res);
                (res.messages || []).forEach(async msg => {
                    // 延迟测量避免notify显示重叠，可能是notify显示的计算的一种缺陷
                    await asleep(1);
                    // 显示警告信息
                    notify('warning', msg, 0);
                });
                return res;
            } catch (exc) {
                if (exc.response?.status !== 401) {
                    console.error(exc);
                }
            }
        },

        async login(username, password) {
            const url = '/user/api/login';
            try {
                const res = await axios.post(url, {username, password});
                this.data = res?.data?.data;
                // 如果登录成功则在本地存储用户名称,如果用户名与本地存储不匹配则删除本地存储
                if (this.data) {
                    afterLogin(`${this.data.corporation.id}::${this.data.username}`);
                }
                return null;
            } catch (exc) {
                if (exc.response?.status !== 401) {
                    console.error(exc);
                }
                if (exc.code === 'ERR_NETWORK') {
                    return `连接服务器失败！${exc.message}`;
                }
                const msg = exc.response?.data?.message ?? exc.response?.statusText ?? exc.message;
                return `登录失败！${msg}`;
            }
        },
        unmountSchdStore() {
            const schd = SchdStore();
            // 卸载相应的store
            schd.$reset();
            schd.$dispose();
        },
        async logout() {
            const url = 'user/api/logout';
            await this.try_load(url);
            afterLogout(this.unmountSchdStore);
            this.data = null;
        },

        async load() {
            if (this.data) return this.data;
            const url = 'user/api/info';
            const res = await this.try_load(url);
            this.data = res?.data?.data;
            return this.data;
        },

        async modify(uid, tags) {
            const url = `user/api/`;
            const res = await axios({
                method: 'post',
                url,
                data: stringify(tags),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                },
            });
            console.debug('response:', res);
            return res;
        },
    },
});

export default UserStore;
export {UserStore};
