<!-- job-list-v2的操作列 -->
<template>
    <el-button
        link
        type="primary"
        size="large"
        @click="callBack('edit')">
        <el-icon><Edit /></el-icon>
    </el-button>
    <el-button
        link
        type="primary"
        size="large"
        :disabled="forbid()"
        @click="callBack('lock')">
        <el-icon><Lock /></el-icon>
    </el-button>
    <el-button
        link
        type="danger"
        size="large"
        @click="callBack('del')">
        <el-icon><Delete /></el-icon>
    </el-button>
</template>
<script setup>
import {defineProps, defineEmits} from 'vue';
import {Delete, Edit, Lock} from '@element-plus/icons-vue';

const props = defineProps({
    row: {
        type: Object,
        default: null,
    },
});

const emit = defineEmits(['edit', 'lock', 'del']);

const forbid = () => {
    if (props.row.error.length > 0) return true;
    if (props.is_finished === '是') return true;
    return false;
};
const callBack = (emitKey) => {
    emit(emitKey, {'serial_number': props.row.serial_number});
};
// const deleteItem = () => {
//     emit('del', {'serial_number': props.row.serial_number});
// };
// const addLock = () => {
//     emit('lock', {'serial_number': props.row.serial_number});
// };
// const editItem = () => {
//     emit('edit', {'serial_number': props.row.serial_number});
// };
</script>
