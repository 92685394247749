<template>
    <el-container class="excel-container">
        <el-header class="excel-header">
            <el-row :gutter="1">
                <el-col :span="8">
                    <ErpTaskInfo v-if="isErpSync" />
                </el-col>
                <el-col :span="2">
                    <el-upload
                        v-if="UStore.is_admin||!isErpSync"
                        :auto-upload="false" :show-file-list="false" :on-change="handleFileChange"
                        :multiple="false"
                        :accept="'.xlsx'">
                        <template #trigger>
                            <el-button type="primary" :disabled="isCalcIng">选择Excel文件</el-button>
                        </template>
                    </el-upload>
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary" :disabled="isCalcIng"
                        class="calc-button" @click="sendToSchd">
                        上传计算
                    </el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="exportExcel">导出文档</el-button>
                </el-col>
                <el-col :span="2">
                    <div class="el-form-item__content">
                        <div class="el-input el-input--default">
                            <el-date-picker
                                v-model="uWbStore.g_plan_date"
                                type="date"
                                :disabled-date="beforeToday"
                                placeholder="计划日期"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD" />
                        </div>
                        <div
                            v-show="isPlanDateError"
                            class="el-form-item__error">
                            日期不能小于今天
                        </div>
                    </div>
                </el-col>
                <el-col :span="2">
                    <el-input
                        v-model="uWbStore.taskRunMaxSec"
                        autocomplete="off"
                        placeholder="最大计算时长"
                        min="15"
                        max="180"
                        input-style="text-align: right;width:120px;"
                        type="number"
                        maxlength="32">
                        <template #suffix>秒</template>
                    </el-input>
                </el-col>
            </el-row>
        </el-header>
        <el-main class="excel-main">
            <el-tabs v-model="tabName" type="border-card" class="excel-tabs">
                <el-tab-pane label="订单列表" :disabled="isCalcIng" name="order">
                    <order-list-v2 :is-erp-sync="isErpSync" />
                </el-tab-pane>
                <el-tab-pane label="任务列表" :disabled="isCalcIng" name="job2">
                    <job-list-v2 />
                </el-tab-pane>
                <!-- <el-tab-pane label="任务列表" :disabled="isCalcIng" name="job">
                    <job-list />
                </el-tab-pane> -->
                <el-tab-pane label="任务锁定" :disabled="isCalcIng" name="lock">
                    <locked-job-list />
                </el-tab-pane>
                <transition name="fade">
                    <el-tab-pane label="排产结果" :disabled="isCalcIng" name="schd">
                        <schd-res-list />
                    </el-tab-pane>
                </transition>
                <el-tab-pane label="计算结果" name="calc-res">
                    <el-button type="primary" @click="getTaskStatus">获取计算状态</el-button>
                    <textarea ref="logref" :value="logs" class="logs" />
                </el-tab-pane>
            </el-tabs>
        </el-main>
        <el-footer />
    </el-container>
</template>
<script>
import {saveAs} from 'file-saver';
import SchdStore from '../stores/schdtabular.js';
import {ElMessage} from 'element-plus';
import OrderListV2 from './order-list-v2.vue';
import dayjs from 'dayjs';
import SchdResList from './schd-res-list.vue';
// import JobList from './job-list.vue';
import JobListV2 from './job-list-v2.vue';
import LockedJobList from './locked-job-list.vue';
import {exportDataToFile} from '../assets/js/schd.js';
import UserStore from '../stores/user.js';
import {LoggerStore} from '../stores/loggers.js';
import {asleep, notify} from '../assets/js/utils.js';
import {nextTick} from 'vue';
import ErpTaskInfo from './erp-task-info.vue';
import {setPassive} from '../assets/js/setEventPassive.js';

// import {useLocalStorage} from '@vueuse/core';
// import {stringify} from '../assets/js/json.js';
export default {
    name: 'ExcelDisplay',
    components: {
        OrderListV2,
        // JobList,
        SchdResList,
        LockedJobList,
        ErpTaskInfo,
        JobListV2,
    },
    setup() {
        const uWbStore = SchdStore();
        const loggerstore = LoggerStore();
        const UStore = UserStore();
        const user = UStore.user;
        return {uWbStore, user, loggerstore, UStore};
    },
    data() {
        const type = `msg${this.user.corp_id}`;
        const logger = this.loggerstore.create(type);
        return {
            isValid: false,
            type,
            logger,
            isCalcIng: false,
            tabName: 'order',
            isPlanDateError: false,
        };
    },
    computed: {
        plan_date() { return this.uWbStore.g_plan_date; },
        logs() {
            return (this.logger?.cache ?? []).map(x => x.message).join('');
        },
        isErpSync() {
            return !!this.user?.corporation.colorprint_db;
        },
    },
    watch: {
        async logs() {
            await asleep(0.1);
            this.$refs.logref.scrollTop = this.$refs.logref.scrollHeight;
        },
        plan_date() {
            if (!dayjs(this.uWbStore.g_plan_date).isValid()) {
                this.isPlanDateError = false;
            } else if (dayjs(this.uWbStore.g_plan_date).isBefore(dayjs(), 'day')) {
                this.isPlanDateError = !this.UStore.is_admin && true;
            } else {
                this.isPlanDateError = false;
            }
        },
    },
    beforeMount() {
        setPassive({eventName: 'wheel', passive: false, capture: false});
    },
    beforeUnmount() {
        setPassive({eventName: 'wheel', passive: true, capture: false});
    },
    async created() {
        if (!dayjs(this.uWbStore.g_plan_date).isValid()) {
            this.isPlanDateError = false;
        } else if (dayjs(this.uWbStore.g_plan_date).isBefore(dayjs(), 'day')) {
            this.isPlanDateError = !this.UStore.is_admin && true;
        }
        this.logger.onfailure = msg => {
            this.unlockTabs();
            this.uWbStore.resetRunningTask();
            this.logger.disconnect();
            notify('warning', '计算失败');
        };
        this.logger.onsuccess = async msg => {
            this.unlockTabs();
            this.activeTab('schd');
            if (msg?.result?.jobs?.length > 0) {
                await this.uWbStore.addCalcedSchd(msg.result.jobs);
                notify('success', '计算成功');
            } else {
                notify('warning', '计算出现问题');
            }
            this.uWbStore.resetRunningTask();
            this.logger.disconnect();
        };
        await nextTick();
        if (this.uWbStore.isTaskRunning()) {
            this.logger.reconnect();
            this.lockedTabs();
            this.activeTab('calc-res');
        }
    },
    methods: {
        isExcelFile(file) {
            // 检查文件类型是否为 Excel
            const excelFiles = [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/wps-office.xlsx',
            ];
            return excelFiles.indexOf(file.type) !== -1;
        },
        activeTab(name) {
            this.tabName = name;
        },
        lockedTabs() {
            // 计算过程中对tab进行锁定,防止其被修改
            this.isCalcIng = true;
        },
        unlockTabs() {
            // 解除锁定
            this.isCalcIng = false;
        },
        async handleFileChange(file) {
            // 读取excel文档
            this.file = file;
            if (!this.isExcelFile(file.raw)) {
                ElMessage({
                    showClose: true,
                    message: '请选择正确的excel文件,仅支持xlsx文件',
                    type: 'error',
                });
                return;
            }
            try {
                await this.uWbStore.loadExcel(file);
            } catch (error) {
                console.debug(error);
            }
        },
        async exportExcel() {
            // const workbook = this.uWbStore.exportExcel();
            const buffer = await exportDataToFile(this.uWbStore);
            const blob = new Blob([buffer],
                {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, 'schd.xlsx');
        },
        isSendToSchdValid() {
            const planDate = dayjs(this.uWbStore.g_plan_date);
            if (!planDate.isValid()) {
                notify('计划日期错误', '必须选择计划日期', 'error', 1500);
                return false;
            }
            const value = parseFloat(this.uWbStore.taskRunMaxSec);
            if (isNaN(value)) {
                notify('计算时长', '必须为数字', 'error', 1500);
                return false;
            }
            if (value > 180) {
                notify('计算时长', '不能超过180秒', 'error', 1500);
                return false;
            }
            if (value < 15) {
                notify('计算时长', '不能少于15秒', 'error', 1500);
                return false;
            }
            return true;
        },
        async sendToSchd() {
            if (!this.isSendToSchdValid()) return;
            try {
                const data = await this.uWbStore.mkCalcPostTPl(this.plan_date);
                const url = '/api/c/pc';
                const res = await this.axios.post(url, data);
                // 激活当前计算任务tab
                // 处理错误信息
                if (res?.data?.error) {
                    console.error(res.data.error);
                    notify(
                        '计算失败',
                        res.data.message,
                        'error',
                        1500);
                    return;
                }
                this.lockedTabs();
                this.activeTab('calc-res');
                this.logger.connect(true);
                this.uWbStore.addRunningTask(res?.data?.task_id);
            } catch (exc) {
                // 解锁tab
                this.unlockTabs();
                this.uWbStore.resetRunningTask();
                notify(exc, 1500);
                console.error(`${exc.name}:${exc.message}`);
            }
            // await this.axios.post(url, data);
        },
        async getTaskStatus() {
            console.log('getTaskStatus', this.uWbStore.currentTaskId);
            const url = `/api/c/pc/tasks/${this.uWbStore.currentTaskId}`;
            try {
                const res = await this.axios.get(url);
                console.log('getTaskStatus', res);
            } catch (exc) {
                console.error(exc);
            }
        },
        beforeToday(date) {
            if (this.UStore.is_admin) {
                return false;
            }
            return dayjs(date).isBefore(dayjs(), 'day');
        },
    },
};
</script>
<style scoped>
/* Add your component styles here */
.el-input__suffix:not(.el-select .el-input__suffix) {
margin-left: -22px;
}
.el-input__inner:not(.el-select .el-input__inner) {
padding-right: 22px;
}
.excel-main::v-deep(.el-tabs__content) {
    height: calc(100vh - 310px);
    overflow-y: hidden;
    flex: 1;
}
.excel-header {
    height:50px;
}
.excel-header>* {
    margin-left: 10px;
}
.el-tab-pane {
    height: 100%;
}
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    transition: all 0.1s ease;
  transform: scale(0.1);
}
.calc-button {
    background-color: #79bbff;
}
.logs {
width: 100%; margin-top: 5px; height: calc(100% - 50px); min-height: 300px
}
</style>
