<template>
    <div class="main_div">
        <el-form
            :model="args"
            class="search_form">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="机台" prop="machine">
                        <el-input
                            v-model="args.machine"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="6">
                    <el-form-item label="类型" class="label_style" prop="category">
                        <el-select
                            ref="categorySelect"
                            v-model="args.category"
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option
                                v-for="(category_data, i) in category_list"
                                :key="i"
                                :label="category_data.name"
                                :value="category_data.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="9" />
                <el-col :span="2">
                    <el-button
                        type="primary"
                        @click="init_data">
                        <el-icon><Search /></el-icon>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table
            ref="elTable"
            class="machine_list"
            :data="machine_list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id"
            :expand-row-keys="expand_row_keys"
            @expand-change="on_expand_change">
            <el-table-column fixed type="expand">
                <template #default="props">
                    <div>
                        <div class="info-box">
                            <div
                                v-for="(item, i) in cardItems"
                                :key="i"
                                class="info-item"
                                :style="{width: item.width}">
                                {{ item.label }}:
                                {{
                                    item.formatter
                                        ? item.formatter(props.row[item.prop])
                                        : props.row[item.prop]
                                }}
                            </div>
                        </div>
                        <tabMachineTags :data="props.row.param_list" />
                        <tabCalendar :data="props.row.calendar" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed type="index" align="center" />
            <el-table-column
                v-for="(col, i) in tableCols"
                :key="i"
                :label="col.label"
                v-bind="col"
                header-align="center"
                :align="col.align" />
            <el-table-column fixed="right" label="" width="100" align="center">
                <template #default="data">
                    <el-tooltip content="编辑机台" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="large"
                            @click="edit_machine($event, data.row)">
                            <el-icon><edit /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            align="center"
            class="page"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="machine_list.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
    </div>
    <machineOpt ref="machineOpt" />
</template>
<script>
import {Search, Edit} from '@element-plus/icons-vue';
import machineOpt from './machine-opt.vue';
import tabMachineTags from './tab-machine-tags.vue';
import tabCalendar from './tab-calendar.vue';
export default {
    name: 'MachineList',
    components: {
        Search,
        Edit,
        machineOpt,
        tabMachineTags,
        tabCalendar,
    },
    data: function() {
        return {
            args: {
                machine: '',
                category: '',
            },
            expand_row_keys: [],
            machine_list: [],
            category_list: [],
            param_defined_list: [],
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 10, // 每页的数据条数
        };
    },
    computed: {
        tableCols() {
            return [
                {prop: 'code', label: '编码', width: 150, align: 'center'},
                {
                    prop: 'name',
                    label: '名称',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'cate',
                    label: '类型',
                    width: 150,
                    align: 'center',
                },
                {
                    prop: 'speed',
                    label: '最大机速',
                    width: 120,
                    align: 'center',
                },
                {prop: 'param_str', label: '参数', align: 'left', width: 200},
                {prop: 'calendar_str', label: '生产日历', align: 'left'},
                {prop: 'state_name', label: '状态', align: 'center', width: 120},
            ];
        },
        cardItems() {
            return [
                {prop: 'code', label: '编码', width: 80},
                {prop: 'name', label: '名称', width: 80},
                {prop: 'cate', label: '类型', width: 80},
                {prop: 'speed', label: '最大机速', width: 120},
            ];
        },
    },
    mounted() {
        this.init_data();
        this.initCategory();
    },
    methods: {
        on_expand_change(row, children) {
            const key = row.id;
            const index = this.expand_row_keys.indexOf(key);
            if (index < 0) {
                this.expand_row_keys.push(key);
            } else {
                this.expand_row_keys.splice(index, 1);
            }
        },
        async edit_machine(_, row) {
            const data = await this.$refs.machineOpt.show({
                data: JSON.parse(JSON.stringify(row)),
                machine_list: this.machine_list,
            });
            if (data) {
                this.init_data();
            }
        },
        async init_data() {
            await this.initParams();
            const url = `/api/machines/`;
            const args = [];
            if (this.args.machine) {
                args.push(`q=${this.args.machine}`);
            }
            if (this.args.category) {
                args.push(`t=${this.args.category}`);
            }
            const args_url = args.length > 0 && '?' + args.join('&') || '';
            const res = await this.axios.get(url + args_url);
            this.machine_list = res.data.data;
            for (let i = 0; i < this.machine_list.length; i++) {
                this.machine_list[i]['cate'] = this.machine_list[i]['category']['name'];
                this.machine_list[i]['state_name'] = this.machine_list[i]['state'] > 0 && '活跃' || '冻结';
                this.machine_list[i]['param_list'] = this.formatParams(this.machine_list[i]['data']);
                this.machine_list[i]['param_str'] = this.machine_list[i]['param_list'].join(';');
                this.getCalendarList(this.machine_list[i]);
            }
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        async initCategory() {
            const url = `/api/categories/`;
            const res = await this.axios.get(url);
            this.category_list = res.data.data;
        },
        async initParams() {
            const url = `/api/parameter-defineds/?t=2`;
            const res = await this.axios.get(url);
            this.param_defined_list = res.data.data.reduce((res, data) => {
                res[data['name']] = data;
                return res;
            }, {});
        },
        async getCalendarList(mach_data) {
            const url = `/api/calendar/?mid=${mach_data['id']}`;
            const res = await this.axios.get(url);
            mach_data['calendar'] = res.data.data;
            mach_data['calendar'] = mach_data['calendar'].reduce((res, data) => {
                data['repeat_name'] = data['repeat'] > 0 && '循环' || '临时';
                data['days_count'] = (data['days'] || []).length;
                data['days'] = data['days'].reduce((res, data) => {
                    if (data?.length > 0) {
                        res.push({
                            'start_time': data[0],
                            'end_time': data[1],
                        });
                    } else {
                        res.push({
                            'start_time': null,
                            'end_time': null,
                        });
                    }
                    return res;
                }, []);
                res.push(data);
                return res;
            }, []);
            mach_data['calendar_str'] = mach_data['calendar'].reduce((res, data) => {
                res.push(data['name']);
                return res;
            }, []);
        },
        formatParams(json) {
            const param_list = [];
            const param_defined_list = this.param_defined_list;
            Object.keys(json).forEach((key) => {
                if (param_defined_list[key]?.type === 'range[integer]') {
                    param_list.push(`${key}:${this.rangeToString(json[key])}`);
                } else {
                    param_list.push(`${key}:${json[key]}`);
                }
            });
            return param_list;
        },
        rangeToString(data) {
            if (Array.isArray(data)) {
                const d = JSON.parse(JSON.stringify(data));
                let flag = true;
                for (let i = 0; i < d.length; i++) {
                    if (typeof d[i] !== 'number') {
                        flag = false;
                        break;
                    }
                }
                if (d.length === 2 && flag) {
                    return d.join('~');
                } else {
                    for (let i = 0; i < d.length; i++) {
                        if (Array.isArray(d[i])) {
                            if (d[i][0] === d[i][1]) {
                                d[i] = d[i][0];
                            } else {
                                d[i] = d[i].join('~');
                            }
                        }
                    }
                    return d.join(',');
                }
            } else {
                return data;
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_div{
    position: relative;
    width: 100%;
    height: 100%;
}
.machine_list{
    position: absolute;
    top:50px;
    bottom: 60px;
    height: calc(100% - 110px);
}
.search_form {
    height:50px;
}
.page{
    position: absolute;
    height: 50px;
    bottom: 10px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
</style>
