/*****************************************************************
 * json转换，支持datetime
 * parse:
 *   {
 *     'string-datetime': 'yyyy-mm-dd'
 *     'object-datetime': {
 *          type: "<datetime>",
 *          data: "yyyy-mm-dd HH:MM:SS"
 *     }
 *   }
 *
 ******************************************************************/

import dayjs from 'dayjs';

const PATTERN_DATETIME = /^\d{4}[/-]\d{2}[/-]\d{2}([ T]\d{2}(:\d{2}){0,2})?$/;
const PATTERN_KEY_TYPE = /^<([\w-:]+)>$/;

/*********************************
 * @options
 *    @options.parse_typed_object: true|false, default true
 *    @options.parse_datetime_string: true|false, default true
 *    @options.parse_datetime_object: true|false, default true
 **********************************/
function json_parse(jsonstr, options) {
    options = {
        parse_typed_object: true,
        parse_datetime_string: true,
        parse_datetime_object: true,
        ...options,
    };
    // eg: yyyy-mm-dd HH:MM:SS
    const parse_datetime_string = options.parse_datetime_string;
    // eg: {"type": "datetime", "date": "..."}
    const parse_datetime_object = options.parse_datetime_object;
    // eg: {"type": "<datetime>", "date": "..."}
    const parse_typed_object = options.parse_typed_object;
    return JSON.parse(jsonstr, (key, value) => {
        try {
            // string pattern to boject
            if (
                typeof value === 'string'
                && parse_datetime_string
                && PATTERN_DATETIME.test(value)
            ) {
                // yyyy-mm-dd HH:MM:SS
                return dayjs(value);
            }
            // object with type & data properties to typed-object
            if (parse_typed_object && value instanceof Object && !Array.isArray(value)) {
                if (value?.type && typeof value.type === 'string') {
                    const m = PATTERN_KEY_TYPE.exec(value.type);
                    if (m) {
                        const keytype = m[1].toLowerCase();
                        if (parse_datetime_object && ['date', 'datetime'].indexOf(keytype) >= 0) {
                            return value.data instanceof dayjs ? value.data : dayjs(value.data);
                        }
                        if (keytype === 'set') {
                            if (!value.data) return new Set();
                            if (Array.isArray(value.data)) {
                                return new Set(value.data);
                            }
                            console.warn('Unknown data for type set', value.data);
                            return new Set();
                        }
                    }
                }
            }
        } catch (err) {
            console.warn(`parse "${value}" error.`, err);
        }
        return value;
    });
}

/*********************************
 * @options
 *    @options.datetime_mode: null, string, object
 *    @options.datetime_format: default "yyyy-mm-dd HH:MM:SS"
 *        null, 不解析；
 *        string："<datetime_format>",
 *        object: {type: "<datetime>", data: "<datetime_format>"}
 *********************************/
function stringify(obj, options) {
    options = {
        datetime_mode: 'string',
        datetime_format: 'YYYY/MM/DD HH:mm:ss',
        ...options,
    };
    const datetime_mode = options.datetime_mode;
    const datetime_format = options.datetime_format;
    const parse_value = value => {
        if (datetime_mode) {
            if (value instanceof dayjs) {
                const data = value.format(datetime_format);
                return datetime_mode === 'object' ? {type: '<datetime>', data} : data;
            }
            if (value instanceof Date) {
                const data = dayjs(value).format(datetime_format);
                return datetime_mode === 'object' ? {type: '<datetime>', data} : data;
            }
        }
        return value;
    };
    const parse_object = obj => {
        return Object.entries(obj).reduce((rs, [key, value]) => {
            rs[key] = parse_value(value);
            return rs;
        }, {});
    };
    const parse_array = arr => {
        return arr.map(value => parse_value(value));
    };
    const parse_set = set => {
        const data = parse_array([...set]);
        return {type: '<set>', data};
    };
    return JSON.stringify(
        obj,
        (key, value) => {
            if (Array.isArray(value)) {
                return parse_array(value);
            }
            if (value instanceof Object) {
                if (value instanceof Set) {
                    return parse_set(value);
                }
                if (value instanceof Map) {
                    return parse_object(Object.fromEntries(value));
                }
                return parse_object(value);
            }
            return value;
        },
        options.space,
    );
}

const json = {json_parse, stringify};

export {json_parse, stringify};
export default json;
