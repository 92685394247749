<template>
    <div class="main_div">
        <el-table
            ref="elTable"
            class="speed_list"
            :data="speed_data.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            :highlight-current-row="true"
            :border="true"
            :row-key="row => row.id">
            <el-table-column fixed type="index" align="center" />
            <el-table-column prop="name" label="工序" width="180" align="center" />
            <el-table-column prop="mode" label="机速模式" width="180" align="center" />
            <el-table-column label="机速" align="left" header-align="center">
                <template #default="scope">
                    <el-table :data="scope.row.speeds_show" :height="0.2*height">
                        <el-table-column
                            v-for="(col, i) in scope.row.tableCols"
                            :key="i"
                            :label="col.label"
                            :prop="col.prop"
                            align="center" />
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="" width="100" align="center">
                <template #default="data">
                    <el-tooltip content="编辑" placement="top">
                        <el-button
                            link
                            type="primary"
                            size="small"
                            @click="edit_speed($event, data.row)">
                            <el-icon style="font-size: 25px;"><edit /></el-icon>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            align="center"
            class="page"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="speed_data.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
    </div>
    <speedOpt ref="speedOpt" />
</template>
<script>
import speedOpt from './speed-opt.vue';
import {Edit} from '@element-plus/icons-vue';
export default {
    name: 'SpeedtList',
    components: {
        speedOpt,
        Edit,
    },
    data: function() {
        return {
            args: {
                speed: '',
            },
            speed_data: [],
            height: window.innerHeight,
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 10, // 每页的数据条数
            sort_rule: {
                print: ['材质', '色数'],
                complex: ['类型', '胶水', '复次'],
            },
        };
    },
    mounted() {
        this.init_data();
    },
    methods: {
        async edit_speed(_, row) {
            const data = await this.$refs.speedOpt.show({
                title: `${row.name}机速设置`,
                data: JSON.parse(JSON.stringify(row)),
            });
            if (data) {
                this.init_data();
            }
        },
        async init_data() {
            const url = `/api/speeds/`;
            const res = await this.axios.get(url);
            this.speed_data = res.data.data;
            this.sortSpeeds();
            for (let i = 0; i < this.speed_data.length; i++) {
                this.speed_data[i]['name'] = this.speed_data[i]['category']['name'];
                this.speed_data[i]['tableCols'] = this.formatCols(this.speed_data[i]);
                this.speed_data[i]['mode'] = this.formatMode(this.speed_data[i].data);
                this.speed_data[i]['speeds_show'] = this.speed_data[i]['speeds'].reduce((result, data) => {
                    const props = {};
                    for (const key in data['props']) {
                        if (this.speed_data[i]['data'][key].mode === 'range') {
                            props[key] = `${data['props'][key][0]}~${data['props'][key][1]}`;
                        } else {
                            props[key] = data['props'][key];
                        }
                    }
                    result.push(Object.assign({}, {'机速(%)': data['value']}, props));
                    return result;
                }, []);
            }
        },
        formatCols(speed_data) {
            const tableCols = [];
            tableCols.push({
                label: '机速(%)',
                prop: '机速(%)',
            });
            for (const key in speed_data.data) {
                tableCols.push({
                    label: key,
                    prop: key,
                });
            }
            return tableCols;
        },
        formatMode(data) {
            return Object.keys(data).join(',');
        },
        // 机速排序
        sortSpeeds() {
            for (let i = 0; i < this.speed_data.length; i++) {
                const data = this.speed_data[i];
                for (let n = 0; n < data.speeds.length; n++) {
                    const s = data.speeds[n];
                    if (this.sort_rule[data['category']['code']]) {
                        const v = [];
                        const sort_list = this.sort_rule[data['category']['code']];
                        const props = s['props'];
                        for (let j = 0; j < sort_list.length; j++) {
                            if (props[sort_list[j]]) {
                                v.push(props[sort_list[j]].toString());
                            }
                        }
                        s['sorting'] = v.join('-');
                    }
                }
                data.speeds.sort((a, b) => a.sorting.localeCompare(b.sorting));
            }
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.main_div{
    position: relative;
    width: 100%;
    height: 100%;
}
.speed_list{
    position: absolute;
    top:0px;
    bottom: 60px;
    height: calc(100% - 60px);
}
.page{
    position: absolute;
    height: 50px;
    bottom: 10px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
.el-table .cell {
  white-space: pre !important;
}
</style>
