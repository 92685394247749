<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        class="dialogClass"
        :title="title"
        align-center
        @keyup.enter="doConfirm">
        <el-form
            ref="proform"
            :model="pro_data"
            class="product_form data_form"
            :rules="pro_rules"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="编码" class="label_style" prop="code">
                        <el-input
                            ref="codeInput"
                            v-model="pro_data.code"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            :disabled="disabled"
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="名称" class="label_style" prop="name">
                        <el-input
                            v-model="pro_data.name"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            :disabled="disabled"
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="规格" class="label_style" prop="spec">
                        <el-input
                            v-model="pro_data.spec"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="结构" class="label_style" prop="stru">
                        <el-input
                            v-model="pro_data.stru"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="客户编码" class="label_style" prop="client">
                        <el-input
                            v-model="pro_data.client"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            :disabled="disabled"
                            maxlength="64" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注" class="label_style" prop="desc">
                        <el-input
                            v-model="pro_data.desc"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="256" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="table_div">
            <el-table
                ref="elTable"
                class="pro_table"
                :data="process_list"
                :highlight-current-row="true"
                :border="true"
                :row-key="row => row.name"
                :expand-row-keys="expand_row_keys"
                @expand-change="on_expand_change">
                <el-table-column fixed type="expand">
                    <template #default="props">
                        <div>
                            <div class="info-box">
                                <div
                                    v-for="(item, i) in cardItems"
                                    :key="i"
                                    class="info-item"
                                    :style="{width: item.width}">
                                    {{ item.label }}:
                                    {{
                                        item.formatter
                                            ? item.formatter(props.row[item.prop])
                                            : props.row[item.prop]
                                    }}
                                </div>
                            </div>
                            <tabProcessTags :data="props.row.params?.split(';')" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed type="index" align="center" />
                <el-table-column prop="cate_name" label="工序类型" width="150" align="center" />
                <el-table-column prop="name" label="工序名称" width="150" align="center" />
                <el-table-column prop="last_process" label="前道工序" width="150" align="center" />
                <el-table-column prop="structure" label="结构" width="180" align="center" />
                <el-table-column prop="standard" label="规格" width="180" align="center" />
                <el-table-column prop="unit" label="产出单位" width="80" align="center" />
                <el-table-column prop="output_ratio" label="产出比例" width="100" align="center" />
                <el-table-column prop="params" label="参数" align="center" />
                <el-table-column fixed="right" label="" width="100" align="center">
                    <template #header>
                        <el-button
                            link
                            type="primary"
                            size=""
                            style="margin-left: 1px"
                            @click="add_process()">
                            <el-icon><plus /></el-icon>
                        </el-button>
                    </template>
                    <template #default="data">
                        <el-tooltip content="编辑工序" placement="top">
                            <el-button
                                link
                                type="primary"
                                size="small"
                                @click="edit_process($event, data.row, data.$index)">
                                <el-icon><edit /></el-icon>
                            </el-button>
                        </el-tooltip>
                        <el-tooltip content="删除工序" placement="top">
                            <el-button
                                link
                                type="primary"
                                size="small"
                                @click="del_process($event, data.$index)">
                                <el-icon><delete /></el-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="dagre_p">
            <svg
                class="dagre_div">
                <g class="container_div" />
            </svg>
        </div>
        <div class="foot_div">
            <el-row>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        @click="visibled = false">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        :disabled="!validate"
                        type="primary"
                        @click="doConfirm">
                        保存
                    </el-button>
                </el-col>
                <el-col :span="4" />
            </el-row>
        </div>
    </el-dialog>
    <DialogProcess ref="DialogProcess" />
</template>

<script>
import {notify} from '../assets/js/utils.js';
import DialogProcess from './dialog-process.vue';
import tabProcessTags from './tab-process-tags.vue';
import {Plus, Delete, Edit} from '@element-plus/icons-vue';
import DagreD3 from 'dagre-d3';
import * as d3 from 'd3';

export default {
    name: 'ProductOpt',
    components: {
        DialogProcess,
        Plus,
        tabProcessTags,
        Delete,
        Edit,
    },
    props: {
    },
    data() {
        return {
            pro_data: {
                code: '',
                name: '',
                spec: '',
                stru: '',
                desc: '',
            },
            disabled: false,
            visibled: false,
            resolve: null,
            reject: null,
            title: '产品添加',
            expand_row_keys: [],
            product_list: [],
            process_list: [],
            confirm_process: [],
            param_defined: [],
            nodes: [],
            edges: [],
            pro_rules: {
                code: [{required: true, message: '请输入产品编码', trigger: ['change', 'blur']}],
                name: [{required: true, message: '请输入产品名称', trigger: ['change', 'blur']}],
                stru: [{required: true, message: '请输入产品结构', trigger: ['change', 'blur']}],
                spec: [{required: true, message: '请输入产品规格', trigger: ['change', 'blur']}],
            },
        };
    },
    computed: {
        cardItems() {
            return [
                {prop: 'cate_name', label: '分类', width: 80},
                {prop: 'name', label: '工序名称', width: 120},
                {prop: 'last_process', label: '前道工序', width: 120},
                {prop: 'structure', label: '结构', width: 80},
                {prop: 'standard', label: '规格', width: 80},
            ];
        },
        validate() {
            return true;
        },
    },
    watch: {
    },
    mounted() {
        // this.init();
    },
    methods: {
        on_expand_change(row, children) {
            const key = row.name;
            const index = this.expand_row_keys.indexOf(key);
            if (children.length) {
                if (index < 0) {
                    this.expand_row_keys.push(key);
                }
            } else {
                if (index >= 0) {
                    this.expand_row_keys.splice(index, 1);
                }
            }
        },
        async show(options) {
            this.title = options.title;
            this.visibled = true;
            this.product_list = options.product_list;
            await this.init_param();
            if (options.data) {
                this.pro_data = options.data;
                if (this.pro_data.sync_code) {
                    this.disabled = true;
                }
                this.process_list = options.data.data['工艺'] || [];
                this.updateNodeData();
                if (options.data.resource) {
                    this.disabled = true;
                    this.pro_data['data']['工艺'] = this.pro_data['data']['工艺'].reduce((res, data) => {
                        const params = {};
                        const param_defined = this.param_defined[data['cate_code']]?.reduce((res, data) => {
                            res.push(data['name']);
                            return res;
                        }, []);
                        for (const key in data) {
                            if (param_defined?.indexOf(key) >= 0) {
                                params[key] = data[key];
                                delete data.key;
                            }
                        }
                        let param_str = '';
                        for (const key in params) {
                            param_str += `${key}:${params[key]};`;
                        }
                        data['param_hide'] = params;
                        data['params'] = param_str;
                        res.push(data);
                        return res;
                    }, []);
                }
                console.log(this.pro_data);
            } else {
                this.pro_data = {};
                this.process_list = [];
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async add_process() {
            const data = await this.$refs.DialogProcess.show({
                title: '工序添加',
                process_list: this.process_list,
                process_data: [],
            });
            this.process_list.push({
                cate_code: data.cate_code,
                cate_name: data.cate_name,
                name: data.name,
                standard: data.standard,
                structure: data.structure,
                unit: data.unit,
                output_ratio: data.output_ratio,
                last_process: data.last_process || [],
                params: data.params,
                param_hide: data.param_hide,
            });
            this.updateNodeData();
        },
        async edit_process(_, row, index) {
            const new_process_list = this.process_list.filter(item => true);
            new_process_list.splice(index, 1);
            const data = await this.$refs.DialogProcess.show({
                title: '工序修改',
                process_list: new_process_list,
                process_data: JSON.parse(JSON.stringify(row)),
            });
            const old_name = this.process_list[index].name;
            const new_name = data.name;
            this.process_list[index] = {
                cate_code: data.cate_code,
                cate_name: data.cate_name,
                name: data.name,
                standard: data.standard,
                structure: data.structure,
                unit: data.unit,
                output_ratio: data.output_ratio,
                last_process: data.last_process || [],
                params: data.params,
                param_hide: data.param_hide,
            };
            this.updateLastProcess(old_name, new_name);
            this.updateNodeData();
        },
        del_process(_, row) {
            this.delLastProcess(this.process_list[row]['name']);
            this.process_list.splice(row, 1);
            this.updateNodeData();
        },
        // 绘制流程图
        async draw() {
            // 创建 Graph 对象
            const g = await new DagreD3.graphlib.Graph().setGraph({
                rankdir: 'LR', // 流程图从下向上显示，默认'TB'，可取值'TB'、'BT'、'LR'、'RL'
                nodesep: 25,
                ranksep: 40,
                edgesep: 40,
            }).setDefaultEdgeLabel(function() { return {}; });

            // Graph添加节点
            this.nodes.forEach(node => {
                g.setNode(node.id, {
                    id: node.id,
                    label: node.nodeName,
                    shape: 'rect',
                    style: 'fill:white;stroke:#fff',
                    labelStyle: 'font-weight:bold',
                    rx: 5,
                    ry: 5,
                    paddingBottom: 15,
                    paddingLeft: 25,
                    paddingRight: 25,
                    paddingTop: 15,
                });
            });
            if (this.nodes.length > 1) {
                this.edges.forEach(edge => {
                    g.setEdge(edge.start, edge.end, {
                        style: 'stroke: grey; fill: none; stroke-width: 2px',
                        arrowheadStyle: 'fill: grey;stroke: grey;',
                        arrowhead: 'normal',
                    });
                });
            }
            const container = d3.select('svg.dagre_div').select('g.container_div');
            // 创建渲染器
            // eslint-disable-next-line new-cap
            const render = new DagreD3.render();
            // 在绘图容器上运行渲染器绘制流程图
            render(container, g);

            const svg = d3.select('svg.dagre_div');
            const zoom = d3
                .zoom() // 缩放支持
                .scaleExtent([0.5, 2]) // 缩放范围
                .on('zoom', function(current) {
                    container.attr('transform', current.transform);
                });
            svg.call(zoom); // 缩放生效
            const {clientWidth, clientHeight} = svg._groups[0][0];
            const {width, height} = g.graph();

            const initScale = 1;
            svg
                .transition()
                .duration(100) // 1s完成过渡
                .call(
                    zoom.transform,
                    d3.zoomIdentity // 居中显示
                        .translate(
                            (clientWidth - width * initScale) / 2,
                            (clientHeight - height * initScale) / 2,
                        )
                        .scale(initScale), // 默认缩放比例
                );
        },
        // 生成节点数据
        updateNodeData() {
            this.nodes = [];
            for (let i = 0; i < this.process_list.length; i++) {
                this.nodes.push({
                    id: i,
                    nodeName: this.process_list[i]['name'],
                });
            }
            this.edges = [];
            const node_map = this.nodes.reduce(function(prev, curr) {
                prev[curr.nodeName] = curr;
                return prev;
            }, {});
            for (let i = 0; i < this.process_list.length; i++) {
                if (this.process_list[i]['last_process']) {
                    for (let j = 0; j < this.process_list[i]['last_process'].length; j++) {
                        this.edges.push({
                            start: node_map[this.process_list[i]['last_process'][j]].id,
                            end: node_map[this.process_list[i]['name']].id,
                        });
                    }
                }
            }
            this.draw();
        },
        // 更新其他工序的上道工序名称
        updateLastProcess(old_name, new_name) {
            for (let i = 0; i < this.process_list.length; i++) {
                const p = this.process_list[i];
                const index = p.last_process.indexOf(old_name); // 获取要替换元素的索引
                if (index !== -1) {
                    p.last_process.splice(index, 1, new_name); // 替换元素为 10
                }
            }
        },
        // 删除其他工序的上道工序名称
        delLastProcess(process_name) {
            for (let i = 0; i < this.process_list.length; i++) {
                const p = this.process_list[i];
                const index = p.last_process.indexOf(process_name); // 获取要替换元素的索引
                if (index !== -1) {
                    p.last_process.splice(index, 1);
                }
            }
        },
        // 编辑页面初始化信息
        init() {
            const init_data = JSON.parse(this.$route.query.data || null);
            if (!init_data) {
                return;
            }
            this.pro_data = init_data;
            this.process_list = init_data.data['工艺'] || [];
            this.updateNodeData();
        },
        async doConfirm() {
            await this.confirmProcessFormat();
            try {
                if (!(await this.$refs.proform?.validate())) {
                    return;
                }
                if (!this.checkRename()) {
                    return;
                }
                if (!this.checkProcessInfo()) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            let sync_code = null;
            if (this.pro_data.resource) {
                sync_code = 'true';
            }
            this.pro_data['sync_code'] = sync_code;
            const url = `/api/products/${this.pro_data.id || ''}`;
            const data = {
                ...this.pro_data,
                data: {
                    '工艺': this.confirm_process,
                },
            };
            const res = await this.axios.post(url, JSON.stringify(data));
            if (res.data.error) {
                notify('error', res.data.error, 0);
                return;
            }
            this.resolve(data);
            // event confirm
            // this.$emit('confirm', data);
            // disable reject
            this.reject = null;
            // close dialog
            this.visibled = false;
        },
        isMulitTailNode() {
            const last_process_list = this.process_list.reduce((pre, cur) => {
                pre.push(...cur.last_process);
                return pre;
            }, []);
            return this.process_list.length - (new Set(last_process_list)).size > 1;
        },
        checkProcessInfo() {
            if (this.process_list.length === 0) {
                notify('error', '请配置产品的生产工艺！', 0);
                return false;
            }

            if (this.isMulitTailNode()) {
                notify('error', '生产路线只能有一个终点工序！', 0);
                return false;
            }
            let flag = true;
            for (let i = 0; i < this.process_list.length; i++) {
                const p = this.process_list[i];
                const param_defined = this.param_defined[p['cate_code']] || [];
                const params = p['param_hide'];
                console.log(param_defined, p);
                if (param_defined.length > 0) {
                    for (let i = 0; i < param_defined.length; i++) {
                        if (!params[param_defined[i]['name']]
                        || params[param_defined[i]['name']].length === 0) {
                            notify('error', `${param_defined[i]['name']}的值为空`, 0);
                            flag = false;
                            break;
                        }
                        if (param_defined[i]['type'] === 'integer') {
                            console.log(Number.isInteger(params[param_defined[i]['name']]),
                                params[param_defined[i]['name']]);
                            if (!Number.isInteger(parseInt(params[param_defined[i]['name']]))) {
                                notify('error', `${param_defined[i]['name']}的值必须为大于0的整数！`, 0);
                                flag = false;
                                break;
                            }
                        }
                        if (param_defined[i]['type'] === 'list[integer]'
                        || param_defined[i]['type'] === 'range[integer]') {
                            for (let i = 0; i < params[param_defined[i]['name']].length; i++) {
                                if (!Number.isInteger(
                                    parseInt(params[param_defined[i]['name']][i]),
                                )) {
                                    notify('error', `${param_defined[i]['name']}的值必须为大于0的整数！`, 0);
                                    flag = false;
                                    break;
                                }
                            }
                            if (!flag) {
                                break;
                            }
                        }
                    };
                    if (!flag) {
                        return flag;
                    }
                }
            }
            return true;
        },
        checkRename() {
            let flag = true;
            for (let i = 0; i < this.product_list.length; i++) {
                if (this.pro_data.id !== this.product_list[i]['id']
                && this.pro_data.code.trim() === this.product_list[i]['code'].trim()) {
                    notify('error', '产品编码重复！', 0);
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        async confirmProcessFormat() {
            this.confirm_process = [];
            for (let i = 0; i < this.process_list.length; i++) {
                const p = this.process_list[i];
                const confirm_params = await this.confirmParamFormat(p, p['param_hide']);
                this.confirm_process.push({
                    cate_code: p['cate_code'],
                    cate_name: p['cate_name'],
                    name: p['name'],
                    last_process: p['last_process'],
                    structure: p['structure'],
                    standard: p['standard'],
                    unit: p['unit'],
                    output_ratio: p['output_ratio'],
                    ...confirm_params,
                });
            }
        },
        async confirmParamFormat(data, params) {
            // const url = `/api/parameter-defineds/?t=1&c=${data.cate_code}`;
            // const res = await this.axios.get(url);
            const param_list = this.param_defined[data.cate_code] || [];
            const confirm_params = {};
            for (let i = 0; i < param_list.length; i++) {
                const p = param_list[i];
                if (p['type'].includes('list') && p.args.style === 'input'
                    && params[p['name']] && !Array.isArray(params[p['name']])) {
                    const str = params[p['name']].replaceAll('，', ',');
                    confirm_params[p['name']] = str.split(',');
                    if (p['type'].includes('integer')) {
                        for (let j = 0; j < confirm_params[p['name']].length; j++) {
                            confirm_params[p['name']][j] = parseInt(confirm_params[p['name']][j]);
                        }
                    }
                    if (p['type'].includes('float')) {
                        for (let j = 0; j < confirm_params[p['name']].length; j++) {
                            confirm_params[p['name']][j] = parseFloat(confirm_params[p['name']][j]);
                        }
                    }
                    if (p['type'].includes('string')) {
                        for (let j = 0; j < confirm_params[p['name']].length; j++) {
                            confirm_params[p['name']][j] = confirm_params[p['name']][j].toString();
                        }
                    }
                } else if (p['type'] === 'range[integer]') {
                    confirm_params[p['name']] = params[p['name']];
                    for (let j = 0; j < confirm_params[p['name']].length; j++) {
                        confirm_params[p['name']][j] = parseInt(confirm_params[p['name']][j]);
                    }
                } else if (p['type'] === 'integer') {
                    confirm_params[p['name']] = parseInt(params[p['name']]);
                } else if (p['type'] === 'float') {
                    confirm_params[p['name']] = parseFloat(params[p['name']]);
                } else {
                    confirm_params[p['name']] = params[p['name']]?.toString();
                }
            }
            return confirm_params;
        },
        async init_param() {
            const url = `/api/parameter-defineds/?t=1`;
            const res = await this.axios.get(url);
            this.param_defined = res.data.data.reduce((res, data) => {
                if (res[data['category']['code']]) {
                    res[data['category']['code']].push(data);
                } else {
                    res[data['category']['code']] = [data];
                }
                return res;
            }, {});
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.dialogClass{
    position: relative !important;
    width: 90% !important;
    height: 100vh;
    max-height: 1200px;
    max-width: 1600px;
    min-height: 600px;
    min-width: 800px;
}
.table_div {
    height: calc(100% - 460px) !important;
    position: absolute;
    top: 200px;
    bottom: 260px;
    width: 100%;
    left: 0px;
    padding: 2px 16px 2px 16px
}
.pro_table {
    height:100% !important;
    width: calc(100% - 32px) !important;
}
.dagre_p {
    position: absolute !important;
    bottom: 55px;
    left:0px;
    width: 100%;
    height: 195px;
}
.dagre_div {
    background-color:lightgray;
    border: 1px solid lightgray;
    width: calc(100% - 32px) !important;
    height: 195px;
    margin-left: 0px;
}
.product_form {
    position: relative;
    height: 150px;
}
.data_form label{
    font-size: 18px;
    padding: 7px 12px;
    text-align: justify;
    text-align-last: justify;
    background-color: #eee;
    font-weight: 400;
    height: 47px;
    border: 1px solid #ccc;
    border-radius: 6px;
    display: block;
    min-width: 150px;
    max-width: 200px;
}
.data_form input {
    font-size: 18px;
    border-radius: 6px;
    color: #555;
    height: 45px;
    /* background-color: #fff; */
}
.container_div{
    width: 100% !important;
}
.btn_button{
    height: 50px !important;
    max-width: 300px !important;
    min-width: 200px !important;
    border-radius: 8px !important;
    font-size: 20px !important;
    text-align: justify !important;
    text-align-last: justify !important;
}
.info-pannel {
    padding-left: 48px;
    padding-right: 8px;
}
.info-box {
    display: flex;
    flex-wrap: wrap;
}
.info-item {
    min-width: 300px;
    width: 25%;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.el-form-item {
    margin-bottom: 0 !important;
}
.foot_div{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    left: 0px;
}
</style>
