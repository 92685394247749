<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        width="80%"
        height="70%"
        align-center
        class="user-editor"
        @keyup.enter="doConfirm">
        <el-form
            ref="proform"
            :model="user_data"
            class="user_form data_form"
            :rules="user_data.id && user_rules_update || user_rules_add"
            :hide-required-asterisk="true"
            :inline-message="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="账号" class="label_style" prop="username">
                        <el-input
                            v-model="user_data.username"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="密码" class="label_style" prop="password">
                        <el-input
                            v-model="user_data.password"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="邮箱" class="label_style" prop="email">
                        <el-input
                            v-model="user_data.email"
                            autocomplete="off"
                            placeholder=""
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="客户" class="label_style" prop="corp_name">
                        <el-input
                            v-model="user_data.corp_name"
                            autocomplete="off"
                            placeholder=""
                            disabled
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="角色" class="label_style" prop="roles">
                        <el-select
                            ref="categorySelect"
                            v-model="user_data.roles"
                            multiple
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option label="admin" value="admin" />
                            <el-option label="user" value="user" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item v-if="user_data.id" label="状态" class="label_style" prop="active">
                        <el-select
                            ref="categorySelect"
                            v-model="user_data.active"
                            autocomplete="off"
                            placeholder="请选择">
                            <el-option label="活跃" value="true" />
                            <el-option label="冻结" value="false" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="foot_div">
            <el-row>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        @click="visibled = false">
                        取消
                    </el-button>
                </el-col>
                <el-col :span="4" />
                <el-col :span="6">
                    <el-button
                        class="btn_button"
                        :disabled="!validate"
                        type="primary"
                        @click="doConfirm">
                        保存
                    </el-button>
                </el-col>
                <el-col :span="4" />
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
import {notify} from '../assets/js/utils.js';
export default {
    name: 'CorporationOpt',
    components: {
    },
    props: {
    },
    data() {
        return {
            height: document.body.clientWidth,
            user_data: {},
            visibled: false,
            resolve: null,
            reject: null,
            title: '账号修改',
            user_list: [],
            user_rules_add: {
                username: [{required: true, message: '请输入账号', trigger: ['change', 'blur']}],
                password: [{required: true, message: '请输入密码', trigger: ['change', 'blur']}],
                email: [{required: true, message: '请输入邮箱', trigger: ['change', 'blur']}],
                roles: [{required: true, message: '请选择账号角色', trigger: ['change', 'blur']}],
            },
            user_rules_update: {
                username: [{required: true, message: '请输入账号', trigger: ['change', 'blur']}],
                email: [{required: true, message: '请输入邮箱', trigger: ['change', 'blur']}],
                roles: [{required: true, message: '请选择账号角色', trigger: ['change', 'blur']}],
            },
        };
    },
    computed: {
        validate() {
            return true;
        },
    },
    mounted() {
    },
    methods: {
        async show(options) {
            this.visibled = true;
            this.title = options.title;
            this.user_list = options.user_list;
            this.user_data = options.data;
            this.user_data['active'] = this.user_data.active?.toString();
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm() {
            try {
                if (!(await this.$refs.proform?.validate())) {
                    return;
                }
                if (!(await this.checked())) {
                    return;
                }
            } catch (e) {
                console.warn(e);
                return;
            }
            const data = this.user_data;
            data['password'] = this.user_data.password || null;
            let success = true;
            let message = '提交失败';
            if (this.user_data.corp_code) {
                const url = `/api/users/${this.user_data.id || ''}`;
                const res = await this.axios.post(url, JSON.stringify(data));
                if (res.data.error) {
                    success = false;
                    message = res.data.error;
                }
            }
            if (!success) {
                notify('error', message, 0);
                return;
            }
            data['role_names'] = data.roles.join(',');
            this.resolve(data);
            // event confirm
            // this.$emit('confirm', data);
            // disable reject
            this.reject = null;
            // close dialog
            this.visibled = false;
        },
        async checked() {
            let flag = true;
            for (let i = 0; i < this.user_list.length; i++) {
                if (this.user_list[i]['id'] !== this.user_data.id
                && this.user_list[i]['username'] === this.user_data.username) {
                    flag = false;
                    notify('error', '账号重复！', 0);
                    break;
                }
            }
            return flag;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.user-editor{
    height: 100% !important;
    max-height: 700px;
    max-width: 1080px;
}
.process_table{
    margin-top: 10px;
    max-height: 400px;
}
fieldset.info-box {
    border: 1px solid #dddddd;
    color: #aaaaaa;
}
fieldset.info-box.machines > .info-item {
    min-width: 150px;
    width: 8%;
}
fieldset.info-box > form {
    width: 100%;
}
legend{
    text-align: left;
}
</style>
