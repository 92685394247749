<template>
    <el-dialog
        v-if="visibled"
        v-model="visibled"
        :title="title"
        align-center
        draggable
        width="75%"
        append-to-body
        modal-class="product-list-dlg"
        :before-close="handleClose"
        @keyup.enter="doConfirm">
        <el-form
            :model="args">
            <el-row>
                <el-col :span="15" />
                <el-col :span="6">
                    <el-form-item label="" prop="product">
                        <el-input
                            v-model="args.product"
                            autocomplete="off"
                            placeholder="名称或编码"
                            clearable
                            maxlength="32" />
                    </el-form-item>
                </el-col>
                <el-col :span="1" />
                <el-col :span="2">
                    <el-button
                        type="primary"
                        @click="init_data">
                        <el-icon><Search /></el-icon>
                    </el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-table
            ref="elTable"
            class="product_list"
            :data="product_data.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            highlight-current-row
            :border="true"
            :row-key="row => row.id"
            :expand-row-keys="expand_row_keys"
            @row-click="selectPrd"
            @row-dblclick="returnPrd"
            @expand-change="on_expand_change">
            <el-table-column fixed type="expand">
                <template #default="props">
                    <div>
                        <tabProcess :data="props.row.data['工艺']" class="sub-table" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed type="index" align="center" />
            <el-table-column prop="code" label="产品编码" width="180" align="center" />
            <el-table-column prop="name" label="产品名称" width="180" align="center" />
            <el-table-column prop="stru" label="结构" width="180" align="center" />
            <el-table-column prop="spec" label="规格" width="180" align="center" />
            <el-table-column prop="state_name" label="状态" width="180" align="center" />
            <el-table-column prop="desc" label="备注" align="center" />
        </el-table>
        <el-pagination
            align="center"
            :current-page="currentPage"
            :page-sizes="[1,5,10,20,50,999]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="product_data.length"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="handleClose">关闭</el-button>
                <el-button type="primary" @click="doConfirm">
                    确认
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
import tabProcess from './tab-process.vue';
import {Search} from '@element-plus/icons-vue';
export default {
    name: 'ProductListDlg',
    components: {
        tabProcess,
        Search,
    },
    data: function() {
        return {
            args: {
                product: '',
            },
            expand_row_keys: [],
            product_data: [],
            currentPage: 1, // 当前页码
            total: 20, // 总条数
            pageSize: 10, // 每页的数据条数
            max_height: document.body.clientWidth * 0.9,
            min_height: document.body.clientWidth * 0.5,
            seleced_prd: null,
            visibled: false,
            resolve: null,
            reject: null,
            title: '产品列表',
        };
    },
    mounted() {
    },
    methods: {
        async show() {
            // await this.initCategory();
            this.visibled = true;
            await this.init_data();
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async doConfirm() {
            this.resolve(this.seleced_prd);
            this.reject = null;
            this.visibled = false;
        },
        on_expand_change(row, children) {
            const key = row.id;
            const index = this.expand_row_keys.indexOf(key);
            if (children.length) {
                if (index < 0) {
                    this.expand_row_keys.push(key);
                }
            } else {
                if (index >= 0) {
                    this.expand_row_keys.splice(index, 1);
                }
            }
        },
        async init_data() {
            const url = `/api/products/`;
            let args = '';
            if (this.args.product) {
                args = `?q=${this.args.product}`;
            }
            const res = await this.axios.get(url + args);
            this.product_data = res.data.data;
            console.log(this.product_data);
            for (let i = 0; i < this.product_data.length; i++) {
                if (this.product_data[i]['data']['工艺'].length > 0) {
                    this.product_data[i]['state_name'] = '已配置';
                } else {
                    this.product_data[i]['state_name'] = '未配置';
                }
            }
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
        },
        // 当前页改变时触发 跳转其他页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.seleced_prd = null;
        },
        selectPrd(row) {
            this.seleced_prd = row;
        },
        handleClose() {
            this.visibled = false;
            this.seleced_prd = null;
            this.reject = null;
            this.resolve(null);
        },
        returnPrd(row) {
            this.seleced_prd = row;
            this.resolve(this.seleced_prd);
            this.reject = null;
            this.visibled = false;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.product_list{
    max-height: v-bind('max_height');
    min-height: v-bind('max_height');
    margin-top: 5px;
}
.sub-table {
    margin-left: 48px;
    margin-top: -5px;
}
.product-list-dlg {
    border: 1px solid red;
    width:100%;
}
</style>
