// import {CreateStorage} from 'vue3-persist-storages';
// import {CreateStorage} from 'pinia-plugin-persistedstate';
const USERINFOKEY = 'uinfo';
const SCHINFOKEY = 'schd-local';
// const storage = new CreateStorage();
function afterLogin(val) {
    const _userInfo = localStorage.getItem(USERINFOKEY);
    // 如果不存在则插入
    // if (!_userInfo) {
    //     storage.setItem(USERINFOKEY, val);
    //     return;
    // }
    // 如果值与传入的不相等则进行进一步处理
    // 删除本地存储的信息
    if (val !== _userInfo) {
        localStorage.removeItem(SCHINFOKEY);
    }
    localStorage.setItem(USERINFOKEY, val);
}
function afterLogout(callBack) {
    // 对store进行处理,且对本地进行存储
    // 先获得本地存储
    const schd_local = localStorage.getItem(SCHINFOKEY);
    if (callBack) {
        callBack();
    }
    if (schd_local) {
        localStorage.removeItem(SCHINFOKEY);
        localStorage.setItem(SCHINFOKEY, schd_local);
    }
}
export default {USERINFOKEY, afterLogin, afterLogout};
export {USERINFOKEY, afterLogin, afterLogout};
