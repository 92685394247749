<template>
    <div>
        <div class="input-number-range is-disabled">
            <div class="flex">
                <div class="from">
                    <!--
                    blur:最小值失焦事件
                    focus:最小值聚焦事件
                    input:最小值输入事件
                    change:最小值change事件
                    -->
                    <el-input
                        ref="input_from"
                        v-model="userInputForm"
                        placeholder="最小值"
                        @blur="handleBlurFrom"
                        @focus="handleFocusFrom"
                        @input="handleInputFrom"
                        @change="handleInputChangeFrom" />
                </div>
                <div class="center">
                    <span>～</span>
                </div>
                <div class="to">
                    <!--
                    blur:最大值失焦事件
                    focus:最大值聚焦事件
                    input:最大值输入事件
                    change:最大值change事件
                    -->
                    <el-input
                        ref="input_to"
                        v-model="userInputTo"
                        placeholder="最大值"
                        @blur="handleBlurTo"
                        @focus="handleFocusTo"
                        @input="handleInputTo"
                        @change="handleInputChangeTo" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'InputNumberRange',
    props: {
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        paramKey: {
            type: String,
            default: null,
        },
        rowIndex: {
            type: Number,
            default: -1,
        },
        // 精度参数
        precision: {
            type: Number,
            default: 0,
            validator(val) {
                return val >= 0 && val === parseInt(val, 10);
            },
        },
        value: {
            type: Array,
            required: true,
        },
    },
    emits: ['input'],
    data() {
        return {
            userInputForm: 0, // 最小值
            userInputTo: null, // 最大值
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                // 判断是否为数字number类型
                if (value instanceof Array && this.precision !== undefined) {
                    const fromVal =
                        value[0] && typeof value[0] === 'number' ? value[0] : 0;
                    const toVal =
                        value[1] && typeof value[1] === 'number' ? value[1] : 0;
                    this.userInputForm = fromVal && fromVal || 0;
                    this.userInputTo = toVal && toVal || 0;
                } else {
                    this.userInputForm = 0;
                    this.userInputTo = null;
                }
            },
        },
    },
    methods: {
        // 根据精度保留数字
        toPrecision(num, precision) {
            if (precision === undefined) precision = 0;
            return parseFloat(
                Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision));
        },
        handleBlurFrom(event) {
            this.$emit('input', event, this.paramKey,
                [this.userInputForm, this.userInputTo], this.rowIndex);
        },

        handleFocusFrom(event) {
        },

        handleBlurTo(event) {
            this.$emit('input', event, this.paramKey,
                [this.userInputForm, this.userInputTo], this.rowIndex);
        },

        handleFocusTo(event) {
        },

        handleInputFrom(value) {
            this.userInputFrom = value;
        },

        handleInputTo(value) {
            this.userInputTo = value;
        },

        // from输入框change事件
        handleInputChangeFrom(value) {
            const newVal = this.setPrecisionValue(value);
            this.userInputForm = newVal;
            // 如果初始化数字的精度不符合代码设置时重置数字
            this.userInputTo = this.setPrecisionValue(this.userInputTo);
            if (!this.userInputForm && !this.userInputTo) {
                return;
            }
            if (!this.userInputTo) {
                this.userInputForm = newVal;
                this.userInputTo = 0;
            } else {
                // 最小值大于最大值时逻辑判断
                this.userInputForm = newVal >= this.userInputTo && this.userInputTo || newVal;
            }
            // this.$emit('input', this.paramKey, [this.userInputForm, this.userInputTo], this.rowIndex);
        },

        // to输入框change事件
        handleInputChangeTo(value) {
            const newVal = this.setPrecisionValue(value);
            this.userInputTo = newVal;
            this.userInputForm = this.setPrecisionValue(this.userInputForm);
            if (!this.userInputTo && !this.userInputForm) {
                return;
            }
            if (!this.userInputForm) {
                this.userInputTo = newVal;
                this.userInputForm = 0;
            } else {
                // 最大值小于最小值时逻辑判断
                this.userInputTo = newVal >= this.userInputForm && newVal || this.userInputForm;
            }
            console.log(this.paramKey, [this.userInputForm, this.userInputTo], this.rowIndex);
            // this.$emit('input', this.paramKey, [this.userInputForm, this.userInputTo], this.rowIndex);
        },

        // 设置成精度数字
        setPrecisionValue(value) {
            if (!value) return 0;
            const newVal = Number(value);
            // 如果是非数字空返回null
            if (isNaN(value)) return 0;
            if (typeof newVal === 'number' && this.precision !== undefined) {
                const val = this.toPrecision(value, this.precision);
                return val;
            }
            return 0;
        },
    },
};
</script>

<style>
/* // 取消element原有的input框样式 */
::v-deep .el-input .el-input__inner {
    border: 0px;
    margin: 0;
    background-color: transparent;
    text-align: center;
}

.input-number-range {
    background-color: #fff;
    border-radius: 4px;
}

.flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.center {
    margin-top: 1px;
}
.is-disabled {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
}
</style>
